/* eslint-disable */

import $ from 'cash-dom';
import throttle from "../../plugins/throttle.js";
import shaka from "shaka-player";
import {octopus} from "../octopus/subtitles-octopus.js";
var jQuery = $;

var mejs = mejs || {};

mejs.meIndex = 0;

// media types accepted by plugins
mejs.plugins = {
	youtube: [
		{version: null, types: ['video/youtube', 'video/x-youtube', 'audio/youtube', 'audio/x-youtube']}
	],
	dash: [{version: null, types: ['application/dash+xml']}]
};

/*
Utility methods
*/
mejs.Utility = {
	encodeUrl: function(url) {
		return encodeURIComponent(url);
	},
	escapeHTML: function(s) {
		return s.toString().split('&').join('&amp;').split('<').join('&lt;').split('"').join('&quot;');
	},
	absolutizeUrl: function(url) {
		var el = document.createElement('div');
		el.innerHTML = '<a href="' + this.escapeHTML(url) + '">x</a>';
		return el.firstChild.href;
	},
	secondsToTimeCode: function(time, forceHours) {
		var hours = Math.floor(time / 3600) % 24,
			minutes = Math.floor(time / 60) % 60,
			seconds = Math.floor(time % 60),
			result =
				( (forceHours || hours > 0) ? (hours < 10 ? '0' + hours : hours) + ':' : '')
					+ (minutes < 10 ? '0' + minutes : minutes) + ':'
					+ (seconds < 10 ? '0' + seconds : seconds);

		return result;
	},

	convertSMPTEtoSeconds: function (SMPTE) {
		if (typeof SMPTE != 'string')
			return false;

		SMPTE = SMPTE.replace(',', '.');

		var secs = 0,
			decimalLen = (SMPTE.indexOf('.') != -1) ? SMPTE.split('.')[1].length : 0,
			multiplier = 1;

		SMPTE = SMPTE.split(':').reverse();

		for (var i = 0; i < SMPTE.length; i++) {
			multiplier = 1;
			if (i > 0) {
				multiplier = Math.pow(60, i);
			}
			secs += Number(SMPTE[i]) * multiplier;
		}
		return Number(secs.toFixed(decimalLen));
	}
};


// Core detector, plugins are added below
mejs.PluginDetector = {

	// main public function to test a plug version number PluginDetector.hasPluginVersion('flash',[9,0,125]);
	hasPluginVersion: function(plugin, v) {
		if(!v) return false;
		var pv = this.plugins[plugin];
		v[1] = v && v[1] || 0;
		v[2] = v && v[2] || 0;
		return (pv[0] > v[0] || (pv[0] == v[0] && pv[1] > v[1]) || (pv[0] == v[0] && pv[1] == v[1] && pv[2] >= v[2])) ? true : false;
	},

	// cached values
	nav: window.navigator,
	ua: window.navigator.userAgent.toLowerCase(),

	// stored version numbers
	plugins: [],
};

mejs.MediaFeatures = {
	init: function() {
		var
			t = this,
			nav = mejs.PluginDetector.nav,
			ua = mejs.PluginDetector.ua.toLowerCase(),
			i,
			v,
			html5Elements = ['source','track','audio','video'];

		// detect browsers (only the ones that have some kind of quirk we need to work around)
		t.isiPad = (ua.match(/ipad/i) !== null);
		t.isiPhone = (ua.match(/iphone/i) !== null);
		t.isiOS = t.isiPhone || t.isiPad;
		t.isAndroid = (ua.match(/android/i) !== null);
		t.isWP = (ua.match(/Windows Phone/i) !== null);
		t.isMobile = t.isiOS || t.isWP || t.isAndroid;
		t.hasTouch = ('ontouchstart' in window);

		// borrowed from Modernizr
		t.svg = !! document.createElementNS &&
				!! document.createElementNS('http://www.w3.org/2000/svg','svg').createSVGRect;

		// create HTML5 media elements for IE before 9, get a <video> element for fullscreen detection
		for (i=0; i<html5Elements.length; i++) {
			v = document.createElement(html5Elements[i]);
		}

		t.supportsMediaTag = (typeof v.canPlayType !== 'undefined');

		try{
			v.canPlayType("video/mp4");
		}catch(e){
			t.supportsMediaTag = false;
		}

		// borrowed from screenfull.js
		var fn = (function () {
			var val, valLength;
			var fnMap = [
				[
					'requestFullscreen',
					'exitFullscreen',
					'fullscreenElement',
					'fullscreenEnabled',
					'fullscreenchange'
				],
				// new WebKit
				[
					'webkitRequestFullscreen',
					'webkitExitFullscreen',
					'webkitFullscreenElement',
					'webkitFullscreenEnabled',
					'webkitfullscreenchange'
				],
				[
					'mozRequestFullScreen',
					'mozCancelFullScreen',
					'mozFullScreenElement',
					'mozFullScreenEnabled',
					'mozfullscreenchange'
				],
				[
					'msRequestFullscreen',
					'msExitFullscreen',
					'msFullscreenElement',
					'msFullscreenEnabled',
					'MSFullscreenChange'
				]
			];
			var i = 0, l = fnMap.length, ret = {};

			for (; i < l; i++) {
				val = fnMap[i];
				if (val && val[1] in document) {
					for (i = 0, valLength = val.length; i < valLength; i++) {
						ret[fnMap[0][i]] = val[i];
					}
					return ret;
				}
			}
			return false;
		})();

		t.hasTrueNativeFullScreen = !!document[fn.fullscreenEnabled];
		t.fullScreenEventName = fn.fullscreenchange;

		t.isFullScreen = function() {
			return !!document[fn.fullscreenElement];
		}

		t.requestFullScreen = function(el) {
			var request = fn.requestFullscreen;
			el[request]();
		}

		t.cancelFullScreen = function() {
			if (t.isFullScreen()) document[fn.exitFullscreen]();
		}
	}
};
mejs.MediaFeatures.init();

/*
extension methods to <video> or <audio> object to bring it into parity with PluginMediaElement (see below)
*/
mejs.HtmlMediaElement = {
	pluginType: 'native',
	isFullScreen: false,

	setCurrentTime: function (time) {
		if (isNaN(time)) return;
		this.currentTime = time;
	},

	setMuted: function (muted) {
		this.muted = muted;
	},

	getVolume: function () {
		//return this.volume;
		return Math.log((Math.E-1) * this.volume + 1);
	},

	setVolume: function(volume) {
		if(volume >= 1) this.volume = 1;
		else if(volume <= 0) this.volume = 0;
		else this.volume = (Math.exp(volume)-1)/(Math.E-1);
		//this.volume = volume;
	},

	// for parity with the plugin versionsconfigur
	stop: function () {
		this.pause();
	},

	// This can be a url string
	// or an array [{src:'file.mp4',type:'video/mp4'},{src:'file.webm',type:'video/webm'}]
	setSrc: function (url) {
		if (typeof url == 'string') {
			this.src = url;
		} else {
			var i, media;

			for (i=0; i<url.length; i++) {
				media = url[i];
				if (this.canPlayType(media.type)) {
					this.src = media.src;
					break;
				}
			}
		}
	},

	setVideoSize: function (width, height) {
		this.width = width;
		this.height = height;
	}
};

/*
Mimics the <video/audio> element
*/
mejs.PluginMediaElement = function (pluginid, pluginType, mediaUrl) {
	this.id = pluginid;
	this.pluginType = pluginType;
	this.src = mediaUrl;
	this.events = {};
	this.attributes = {};
};

// JavaScript values and ExternalInterface methods that match HTML5 video properties methods
// http://www.adobe.com/livedocs/flash/9.0/ActionScriptLangRefV3/fl/video/FLVPlayback.html
// http://www.whatwg.org/specs/web-apps/current-work/multipage/video.html
mejs.PluginMediaElement.prototype = {
	// special
	pluginElement: null,
	pluginType: '',
	isFullScreen: false,

	// not implemented :(
	playbackRate: -1,
	defaultPlaybackRate: -1,
	seekable: [],

	// HTML5 read-only properties
	paused: true,
	ended: false,
	seeking: false,
	duration: 0,
	error: null,
	tagName: '',

	// HTML5 get/set properties, but only set (updated by event handlers)
	muted: false,
	volume: 1,
	currentTime: 0,

	// HTML5 methods
	play: function () {
		if (this.pluginApi != null) {
			if (this.pluginType == 'youtube') {
				this.pluginApi.playVideo();
			} else {
				this.pluginApi.playMedia();
			}
			this.paused = false;
		}
	},
	load: function () {
		if (this.pluginApi != null) {
			if (this.pluginType == 'youtube') {
			} else {
				this.pluginApi.loadMedia();
			}

			this.paused = false;
		}
	},
	pause: function () {
		if (this.pluginApi != null) {
			if (this.pluginType == 'youtube') {
				if(this.pluginApi.getPlayerState() == 1) this.pluginApi.pauseVideo();
			} else {
				this.pluginApi.pauseMedia();
			}

			this.paused = true;
		}
	},
	stop: function () {
		if (this.pluginApi != null) {
			if (this.pluginType == 'youtube') {
				this.pluginApi.stopVideo();
			} else {
				this.pluginApi.stopMedia();
			}
			this.paused = true;
		}
	},
	canPlayType: function(type) {
		var i,
			j,
			pluginInfo,
			pluginVersions = mejs.plugins[this.pluginType];

		for (i=0; i<pluginVersions.length; i++) {
			pluginInfo = pluginVersions[i];
			if(!pluginInfo) continue;

			// test if user has the correct plugin version
			if (mejs.PluginDetector.hasPluginVersion(this.pluginType, pluginInfo.version)) {
				// test for plugin playback types
				for (j=0; j<pluginInfo.types.length; j++) {
					// find plugin that can play the type
					if (type == pluginInfo.types[j]) {
						return 'probably';
					}
				}
			}
		}

		return '';
	},

	positionFullscreenButton: function(x,y,visibleAndAbove) {
		if (this.pluginApi != null && this.pluginApi.positionFullscreenButton) {
			this.pluginApi.positionFullscreenButton(Math.floor(x),Math.floor(y),visibleAndAbove);
		}
	},

	hideFullscreenButton: function() {
		if (this.pluginApi != null && this.pluginApi.hideFullscreenButton) {
			this.pluginApi.hideFullscreenButton();
		}
	},

	// custom methods since not all JavaScript implementations support get/set

	// This can be a url string
	// or an array [{src:'file.mp4',type:'video/mp4'},{src:'file.webm',type:'video/webm'}]
	setSrc: function (url) {
		if (this.pluginApi != null && this.pluginType == 'youtube') return;
		this.pluginApi.setSrc(mejs.Utility.absolutizeUrl(url));
		this.src = mejs.Utility.absolutizeUrl(url);
	},
	setCurrentTime: function (time) {
		if (this.pluginApi != null) {
			if (this.pluginType == 'youtube') {
				this.pluginApi.seekTo(time);
			} else {
				this.pluginApi.setCurrentTime(time);
			}

			this.currentTime = time;
		}
	},
	setVolume: function (volume) {
		if (this.pluginApi != null) {
			// same on YouTube and MEjs
			if (this.pluginType == 'youtube') {
				this.pluginApi.setVolume(volume * 100);
			} else {
				this.pluginApi.setVolume(volume);
			}
			this.volume = volume;
		}
	},
	getVolume: function () {
		return this.volume;
	},
	setMuted: function (muted) {
		if (this.pluginApi != null) {
			if (this.pluginType == 'youtube') {
				if (muted) {
					this.pluginApi.mute();
				} else {
					this.pluginApi.unMute();
				}
				this.muted = muted;
				this.dispatchEvent('volumechange');
			} else {
				this.pluginApi.setMuted(muted);
			}
			this.muted = muted;
		}
	},

	// additional non-HTML5 methods
	setVideoSize: function (width, height) {
			if (this.pluginElement && this.pluginElement.style) {
				this.pluginElement.style.width = width + 'px';
				this.pluginElement.style.height = height + 'px';
			}
			if (this.pluginApi != null && this.pluginApi.setVideoSize) {
				this.pluginApi.setVideoSize(width, height);
			}
	},

	setFullscreen: function (fullscreen) {
		if (this.pluginApi != null && this.pluginApi.setFullscreen) {
			this.pluginApi.setFullscreen(fullscreen);
		}
	},

	enterFullScreen: function() {
		if (this.pluginApi != null && this.pluginApi.setFullscreen) {
			this.setFullscreen(true);
		}

	},

	exitFullScreen: function() {
		if (this.pluginApi != null && this.pluginApi.setFullscreen) {
			this.setFullscreen(false);
		}
	},

	// start: fake events
	addEventListener: function (eventName, callback, bubble) {
		this.events[eventName] = this.events[eventName] || [];
		this.events[eventName].push(callback);
	},
	removeEventListener: function (eventName, callback) {
		if (!eventName) { this.events = {}; return true; }
		var callbacks = this.events[eventName];
		if (!callbacks) return true;
		if (!callback) { this.events[eventName] = []; return true; }
		for (var i = 0; i < callbacks.length; i++) {
			if (callbacks[i] === callback) {
				this.events[eventName].splice(i, 1);
				return true;
			}
		}
		return false;
	},
	dispatchEvent: function (eventName) {
		var args,
			callbacks = this.events[eventName];

		if (callbacks) {
			args = Array.prototype.slice.call(arguments, 1);
			for (var i = 0; i < callbacks.length; i++) {
				callbacks[i].apply(this, args);
			}
		}
	},
	// end: fake events

	// fake DOM attribute methods
	hasAttribute: function(name){
		return (name in this.attributes);
	},
	removeAttribute: function(name){
		delete this.attributes[name];
	},
	getAttribute: function(name){
		if (this.hasAttribute(name)) {
			return this.attributes[name];
		}
		return '';
	},
	setAttribute: function(name, value){
		this.attributes[name] = value;
	},

	remove: function() {
		mejs.MediaPluginBridge.unregisterPluginElement(this.pluginElement.id);
	}
};

// Handles calls from Flash and reports them as native <video/audio> events and properties
mejs.MediaPluginBridge = {
	pluginMediaElements:{},
	htmlMediaElements:{},

	registerPluginElement: function(id, pluginMediaElement, htmlMediaElement) {
		this.pluginMediaElements[id] = pluginMediaElement;
		this.htmlMediaElements[id] = htmlMediaElement;
	},

	unregisterPluginElement: function(id) {
		delete this.pluginMediaElements[id];
		delete this.htmlMediaElements[id];
	},

	initPlugin: function (id) {
		var pluginMediaElement = this.pluginMediaElements[id],
			htmlMediaElement = this.htmlMediaElements[id];

		if (pluginMediaElement) {
			// find the javascript bridge
			switch (pluginMediaElement.pluginType) {
				case "youtube":
					pluginMediaElement.pluginApi.setVideoSize = function (width, height) {
						this.setSize(width, height);
					};
					break;
			}

			if (pluginMediaElement.pluginApi != null && pluginMediaElement.success) {
				pluginMediaElement.success(pluginMediaElement, htmlMediaElement);
			}
		}
	},
};

/*
Default options
*/
mejs.MediaElementDefaults = {
	// allows testing on HTML5, flash
	// auto: attempts to detect what the browser can do
	// auto_plugin: prefer plugins and then attempt native HTML5
	// native: forces HTML5 playback
	// shim: disallows HTML5, will attempt either Flash or Silverlight
	// none: forces fallback view
	mode: 'auto',
	// remove or reorder to change plugin priority and availability
	plugins: ['youtube','dash'],
	// overrides the type specified, useful for dynamic instantiation
	type: '',
	// default if the <video width> is not specified
	defaultVideoWidth: 480,
	// default if the <video height> is not specified
	defaultVideoHeight: 270,

	tracks: [],
	// initial volume for player
	success: function () { },
	error: function () { }
};

/*
Determines if a browser supports the <video> or <audio> element
and returns either the native element or a Flash version that
mimics HTML5 MediaElement
*/
mejs.MediaElement = function(el, o) {
	return mejs.HtmlMediaElementShim.create(el,o);
};

mejs.HtmlMediaElementShim = {
	create: function(el, o) {
		var
			options = $.extend({},mejs.MediaElementDefaults,o),
			htmlMediaElement = (typeof(el) == 'string') ? document.getElementById(el) : el,
			src = htmlMediaElement.getAttribute('src'),
			poster = htmlMediaElement.getAttribute('poster'),
			autoplay =  htmlMediaElement.getAttribute('autoplay'),
			preload =  htmlMediaElement.getAttribute('preload'),
			playback,
			prop;

		// extend options
		for(prop in o) {
			options[prop] = o[prop];
		}

		// clean up attributes
		src = 		(typeof src == 'undefined'	|| src === null || src == '') ? null : src;
		poster =	(typeof poster == 'undefined'	|| poster === null) ? '' : poster;
		preload = 	(typeof preload == 'undefined'	|| preload === null || preload === 'false') ? 'none' : preload;
		autoplay = 	!(typeof autoplay == 'undefined' || autoplay === null || autoplay === 'false');

		// test for HTML5 and plugin capabilities
		playback = this.determinePlayback(htmlMediaElement, options, src);
		playback.url = (playback.url !== null) ? mejs.Utility.absolutizeUrl(playback.url) : '';

		htmlMediaElement.dash_player = false;

		if(playback.method == 'native' && options.parent.video.dash) {
			htmlMediaElement.dash_player = mejs.DashApi.init(htmlMediaElement);
			/*let eventOnDash = new CustomEvent("onDash", {detail: {player: htmlMediaElement.dash_player}});
			htmlMediaElement.dispatchEvent(eventOnDash);*/
		}

		if(playback.method == 'native') {
			// add methods to native HTMLMediaElement
			return this.updateNative(playback, options, autoplay, preload);
		} else if(playback.method !== '') {
			// create plugin to mimic HTMLMediaElement
			return this.createPlugin(playback, options);
		} else {
			// boo, no HTML5, no plugins.
			this.createErrorMessage(playback, options, poster);

			return this;
		}
	},

	determinePlayback: function(htmlMediaElement, options, src) {
		var
			mediaFiles = [],
			i,
			j,
			k,
			l,
			n,
			type,
			result = { method: '', url: '', htmlMediaElement: htmlMediaElement, isVideo: true},
			pluginName,
			pluginVersions,
			pluginInfo,
			dummy,
			media;
		// STEP 1: Get URL and type from <video src> or <source src>

		// supplied type overrides <video type> and <source type>
		if (typeof options.type != 'undefined' && options.type !== '') {
			// accept either string or array of types
			if (typeof options.type == 'string') {
				mediaFiles.push({type:options.type, url:src});
			} else {
				for (i=0; i<options.type.length; i++) {
					mediaFiles.push({type:options.type[i], url:src});
				}
			}
		// test for src attribute first
		} else if (src !== null) {
			type = this.formatType(src, htmlMediaElement.getAttribute('type'));
			mediaFiles.push({type:type, url:src});
		// then test for <source> elements
		} else {
			// test <source> types to see if they are usable
			for (i = 0; i < htmlMediaElement.childNodes.length; i++) {
				n = htmlMediaElement.childNodes[i];
				if (n.nodeType == 1 && n.tagName.toLowerCase() == 'source') {
					src = n.getAttribute('src');
					type = this.formatType(src, n.getAttribute('type'));
					media = n.getAttribute('media');

					if (!media || !window.matchMedia || (window.matchMedia && window.matchMedia(media).matches)) {
						mediaFiles.push({type:type, url:src});
					}
				}
			}
		}

		// STEP 2: Test for playback method

		// test for native playback first
		if (options.mode === 'auto' || options.mode === 'auto_plugin' || options.mode === 'native') {
			for (i = 0; i<mediaFiles.length; i++) {
				// normal check
				type = mediaFiles[i].type;
				if(type == "video/mp4") type = 'video/mp4; codecs="avc1.42E01E"';
				if (htmlMediaElement.canPlayType(type).replace(/no/, '') !== '') {
					result.method = 'native';
					result.url = mediaFiles[i].url;
					break;
				}
			}

			if (result.method === 'native') {
				if (result.url !== null) {
					htmlMediaElement.src = result.url;
				}

				// if `auto_plugin` mode, then cache the native result but try plugins.
				if (options.mode !== 'auto_plugin') {
					return result;
				}
			}
		}

		// if native playback didn't work, then test plugins
		if (options.mode === 'auto' || options.mode === 'auto_plugin' || options.mode === 'shim') {
			for (i=0; i<mediaFiles.length; i++) {
				type = mediaFiles[i].type;

				for (j=0; j<options.plugins.length; j++) {
					pluginName = options.plugins[j];

					// test version of plugin (for future features)
					pluginVersions = mejs.plugins[pluginName];

					for (var key in pluginVersions) {
						pluginInfo = pluginVersions[key];
						// test if user has the correct plugin version

						// for youtube
						if (pluginInfo.version == null ||
							mejs.PluginDetector.hasPluginVersion(pluginName, pluginInfo.version)) {
							// test for plugin playback types
							for (l=0; l<pluginInfo.types.length; l++) {
								// find plugin that can play the type
								if (type == pluginInfo.types[l]) {
									result.method = pluginName;
									result.url = mediaFiles[i].url;
									return result;
								}
							}
						}
					}
				}
			}
		}

		// at this point, being in 'auto_plugin' mode implies that we tried plugins but failed.
		// if we have native support then return that.
		if (options.mode === 'auto_plugin' && result.method === 'native') {
			return result;
		}

		// what if there's nothing to play? just grab the first available
		if (result.method === '' && mediaFiles.length > 0) {
			result.url = mediaFiles[0].url;
		}
		return result;
	},

	formatType: function(url, type) {
		// if no type is supplied, fake it with the extension
		if (url && !type) {
			return this.getTypeFromFile(url);
		} else {
			// only return the mime part of the type in case the attribute contains the codec
			// see http://www.whatwg.org/specs/web-apps/current-work/multipage/video.html#the-source-element
			// `video/mp4; codecs="avc1.42E01E, mp4a.40.2"` becomes `video/mp4`

			if (type && ~type.indexOf(';')) {
				return type.substr(0, type.indexOf(';'));
			} else {
				return type;
			}
		}
	},

	getTypeFromFile: function(url) {
		url = url.split('?')[0];
		var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
		return (/(mp4|webm)/gi.test(ext) ? 'video' : 'audio') + '/' + this.getTypeFromExtension(ext);
	},

	getTypeFromExtension: function(ext) {
		switch (ext) {
			case 'mp4':
				return 'mp4';
			case 'webm':
				return 'webm';
			default:
				return ext;
		}
	},

	createErrorMessage: function(playback, options, poster) {
		var
		htmlMediaElement = playback.htmlMediaElement,
		errorContainer = document.createElement('div');

		errorContainer.className = 'me-cannotplay';

		try {
			errorContainer.style.width = htmlMediaElement.width + 'px';
			errorContainer.style.height = htmlMediaElement.height + 'px';
		} catch (e) {}

		if (options.customError) {
			errorContainer.innerHTML = options.customError;
		} else {
			errorContainer.innerHTML = '<img src="' + poster + '" width="100%" height="100%" />';
		}

		htmlMediaElement.parentNode.insertBefore(errorContainer, htmlMediaElement);
		htmlMediaElement.style.display = 'none';

		options.error(new Error('MediaElement: No valid playback method available'), htmlMediaElement);
	},

	createPlugin: function(playback, options) {
		var
			htmlMediaElement = playback.htmlMediaElement,
			width = 1,
			height = 1,
			pluginid = 'me_' + playback.method + '_' + (mejs.meIndex++),
			pluginMediaElement = new mejs.PluginMediaElement(pluginid, playback.method, playback.url),
			container = document.createElement('div');

		// copy tagName from html media element
		pluginMediaElement.tagName = htmlMediaElement.tagName

		// copy attributes from html media element to plugin media element
		for (var i = 0; i < htmlMediaElement.attributes.length; i++) {
			var attribute = htmlMediaElement.attributes[i];
			if (attribute.specified == true) {
				pluginMediaElement.setAttribute(attribute.name, attribute.value);
			}
		}

		if (playback.isVideo) {
			width = (options.pluginWidth > 0) ? options.pluginWidth : (options.videoWidth > 0) ? options.videoWidth : (htmlMediaElement.getAttribute('width') !== null) ? htmlMediaElement.getAttribute('width') : options.defaultVideoWidth;
			height = (options.pluginHeight > 0) ? options.pluginHeight : (options.videoHeight > 0) ? options.videoHeight : (htmlMediaElement.getAttribute('height') !== null) ? htmlMediaElement.getAttribute('height') : options.defaultVideoHeight;

			// in case of '%' make sure it's encoded
			width = mejs.Utility.encodeUrl(width);
			height = mejs.Utility.encodeUrl(height);
		}

		// register plugin
		pluginMediaElement.success = options.success;
		mejs.MediaPluginBridge.registerPluginElement(pluginid, pluginMediaElement, htmlMediaElement);

		// add container (must be added to DOM before inserting HTML for IE)
		container.className = 'me-plugin';
		container.id = pluginid + '_container';

		if (playback.isVideo) {
				htmlMediaElement.parentNode.insertBefore(container, htmlMediaElement);
		} else {
				document.body.insertBefore(container, document.body.childNodes[0]);
		}

		if (playback.method == 'youtube') {
				var
					videoId = playback.url.substr(playback.url.lastIndexOf('=')+1);
					youtubeSettings = {
						container: container,
						containerId: container.id,
						pluginMediaElement: pluginMediaElement,
						pluginId: pluginid,
						videoId: videoId,
						height: height,
						width: width
					};
				mejs.YouTubeApi.enqueueIframe(youtubeSettings);
		}
		// hide original element
		htmlMediaElement.style.display = 'none';
		// prevent browser from autoplaying when using a plugin
		htmlMediaElement.removeAttribute('autoplay');

		// FYI: options.success will be fired by the MediaPluginBridge
		return pluginMediaElement;
	},

	updateNative: function(playback, options) {
		var htmlMediaElement = playback.htmlMediaElement,
			m;

		// add methods to video object to bring it into parity with Flash Object
		for (m in mejs.HtmlMediaElement) {
			htmlMediaElement[m] = mejs.HtmlMediaElement[m];
		}

		// fire success code
		options.success(htmlMediaElement, htmlMediaElement);

		return htmlMediaElement;
	}
};

// YouTube Iframe API
mejs.YouTubeApi = {
	isIframeStarted: false,
	isIframeLoaded: false,
	loadIframeApi: function() {
		if (!this.isIframeStarted) {
			var tag = document.createElement('script');
			tag.src = "https://www.youtube.com/player_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
			this.isIframeStarted = true;
		}
	},
	iframeQueue: [],
	enqueueIframe: function(yt) {
		if (this.isLoaded) {
			this.createIframe(yt);
		} else {
			this.loadIframeApi();
			this.iframeQueue.push(yt);
		}
	},
	createIframe: function(settings) {
		var
		pluginMediaElement = settings.pluginMediaElement,
		player = new YT.Player(settings.containerId, {
			height: settings.height,
			width: settings.width,
			videoId: settings.videoId,
			playerVars: {controls:0},
			events: {
				'onReady': function() {
					// hook up iframe object to MEjs
					settings.pluginMediaElement.pluginApi = player;

					// init mejs
					mejs.MediaPluginBridge.initPlugin(settings.pluginId);

					// create timer
					setInterval(function() {
						mejs.YouTubeApi.createEvent(player, pluginMediaElement, 'timeupdate');
					}, 250);
				},
				'onStateChange': function(e) {
					mejs.YouTubeApi.handleStateChange(e.data, player, pluginMediaElement);
				}
			}
		});
	},

	createEvent: function (player, pluginMediaElement, eventName) {
		var obj = {
			type: eventName,
			target: pluginMediaElement
		};

		if (player && player.getDuration) {
			// time
			pluginMediaElement.currentTime = obj.currentTime = player.getCurrentTime();
			pluginMediaElement.duration = obj.duration = player.getDuration();

			// state
			obj.paused = pluginMediaElement.paused;
			obj.ended = pluginMediaElement.ended;

			// sound
			obj.muted = player.isMuted();
			obj.volume = player.getVolume() / 100;

			// progress
			obj.bytesTotal = player.getVideoBytesTotal();
			obj.bufferedBytes = player.getVideoBytesLoaded();

			// fake the W3C buffered TimeRange
			var bufferedTime = obj.bufferedBytes / obj.bytesTotal * obj.duration;

			obj.target.buffered = obj.buffered = {
				start: function() {
					return 0;
				},
				end: function () {
					return bufferedTime;
				},
				length: 1
			};
		}

		// send event up the chain
		pluginMediaElement.dispatchEvent(obj.type, obj);
	},

	iFrameReady: function() {
		this.isLoaded = true;
		this.isIframeLoaded = true;

		while (this.iframeQueue.length > 0) {
			var settings = this.iframeQueue.pop();
			this.createIframe(settings);
		}
	},

	handleStateChange: function(youTubeState, player, pluginMediaElement) {
		switch (youTubeState) {
			case -1: // not started
				pluginMediaElement.paused = true;
				pluginMediaElement.ended = true;
				mejs.YouTubeApi.createEvent(player, pluginMediaElement, 'loadedmetadata');
				//createYouTubeEvent(player, pluginMediaElement, 'loadeddata');
				break;
			case 0:
				pluginMediaElement.paused = false;
				pluginMediaElement.ended = true;
				mejs.YouTubeApi.createEvent(player, pluginMediaElement, 'ended');
				break;
			case 1:
				pluginMediaElement.paused = false;
				pluginMediaElement.ended = false;
				mejs.YouTubeApi.createEvent(player, pluginMediaElement, 'play');
				mejs.YouTubeApi.createEvent(player, pluginMediaElement, 'playing');
				break;
			case 2:
				pluginMediaElement.paused = true;
				pluginMediaElement.ended = false;
				mejs.YouTubeApi.createEvent(player, pluginMediaElement, 'pause');
				break;
			case 3: // buffering
				mejs.YouTubeApi.createEvent(player, pluginMediaElement, 'progress');
				break;
			case 5:
				// cued?
				break;
		}
	}
}
// IFRAME
function onYouTubePlayerAPIReady() {
	mejs.YouTubeApi.iFrameReady();
}
mejs.DashApi = {
	offline_db: false,
	offline_content: false,
	src: false,
	shaka_player: false,
	progress: 0,

	init: function(htmlMediaElement) {
		if (!this.isBrowserSupported()) return 0;
		this.offline_db = false;
		this.offline_content = false;
		var player = new shaka.Player(htmlMediaElement);
		player.addEventListener('error', this.onErrorEvent);
		player.addEventListener('segmentbuffering', this.onSegmentBuffering);
		//player.addEventListener('adaptation', function(e) {console.log(e);});

		/*if(chrome.cast) {
			var cast = new shaka.cast.CastProxy(htmlMediaElement, player, 'A15A181D');
			if(cast) {
				player = cast.getPlayer();
				var container = $("#cast");
				if(container.length) {
					$(".cast").removeClass("cast");
					container.click(function() {
						cast.cast().then(function() {console.log('cast');}).
							catch(function(e) {console.error('cast fail', e); container.html("Ошибка");});
					});
				}
			}
		}*/

		return player;
	},
	isBrowserSupported: function() {
		shaka.polyfill.installAll();
		var supported = shaka.Player.isBrowserSupported();
		/*if(!supported) {
			$.get('/ajax/error');
		}*/
		return supported;
	},
	onErrorEvent: function(event) {
		var error = event.detail;
		console.error('Error code', error.code, 'object', error);
	},
	onSegmentBuffering: function(event) {
		mejs.DashApi.progress = event.bufferingProgress;
	},
	offlineInit: function(shaka_player, src) {
		var Storage = shaka.offline.Storage, offline_control = $("#offline"), t = this;
		if (!offline_control.length) return 0;
		if (!Storage.support()) return 0;
		t.src = src;
		t.shaka_player = shaka_player;
		$(".offline").removeClass("offline");

		this.offline_db = new Storage(shaka_player);
		this.offline_db.list().then(function(storedContents) {
			storedContents.forEach(function(storedContent) {
				if(storedContent.originalManifestUri == src) t.offline_content = storedContent;
			});
			t.offlineControl();
		});
	},
	offlineStore: function() {
		var t = mejs.DashApi;
		if (!t.offline_db) return false;
		var offline_control = $("#offline");
		$("#offline").html("<img src='/image/player/ajax.gif'>");

		t.offline_db.configure({
			trackSelectionCallback: function(allTracks) {
				return allTracks;
			},
			progressCallback: function(storedContent, percentComplete) {offline_control.html("<div class='progress' style='width:100px;'><div class='bar' style='width:" + (percentComplete * 100).toFixed() + "px;'></div></div>" + (percentComplete * 100).toFixed(2) + "%");}
		});
		t.offline_db.store(t.src, t.options.parent.video.v_id).then(function(storedContent) {
			if(t.src == storedContent.originalManifestUri) {
				t.offline_content = storedContent;
				t.offlineControl();
				t.offlineReload(storedContent.offlineUri);
			}
		}).catch(function(reason) {$("#offline").html("Ошибка: " + reason);});
		return false;
	},
	offlineRemove: function() {
		var t = mejs.DashApi;
		if (!t.offline_content) return false;
		t.offline_db.remove(t.offline_content).then(function() {
			t.offline_content = false;
			t.offlineControl();
			t.offlineReload(t.src);
		}).catch(function(reason) {$("#offline").html("Ошибка: " + reason);});
		return false;
	},
	offlineReload: function(src) {
		/*var t = this;
		if(Static.player.isLoaded) {
			var paused = Static.player.media.paused, pos = Static.player.media.currentTime;
			if(paused) Static.player.pause();
			t.shaka_player.destroy().then(function() {
				t.shaka_player = new shaka.Player(Static.player.media);
				Static.player.media.dash_player = t.shaka_player;
				t.shaka_player.load(src, pos);
				if(!paused) Static.player.play();
			});
		}*/
	},
	offlineControl: function() {
		/*var t = this;
			if (t.offline_content) {
				$("#offline").html("<a href='#' id='offline_del'><img src='/image/player/delete-16.png'></a>");
				$("#offline_del").on('click', t.offlineRemove);
			} else {
				$("#offline").html("<a href='#' id='offline_store'><img src='/image/player/drive.png'></a>");
				$("#offline_store").on('click', t.offlineStore);
			}*/
	}
}

window.mejs = mejs;
window.MediaElement = mejs.MediaElement;

if (typeof jQuery != 'undefined') {
	mejs.$ = jQuery;
} else if (typeof cash != 'undefined') {
	mejs.$ = cash;
}
(function ($) {
	// default player values
	mejs.MepDefaults = {
		// url to poster (to fix iOS 3.x)
		poster: '',
		// When the video is ended, we can show the poster.
		showPosterWhenEnded: false,
		// default if the <video width> is not specified
		defaultVideoWidth: 480,
		// default if the <video height> is not specified
		defaultVideoHeight: 270,
		// if set, overrides <video width>
		videoWidth: -1,
		// if set, overrides <video height>
		videoHeight: -1,

		// default amount to move back when back key is pressed
		defaultSeekBackwardInterval: function(media) {
			return media.duration > 100 ? 5 : Math.ceil(media.duration * 0.05);
		},
		// default amount to move forward when forward key is pressed
		defaultSeekForwardInterval: function(media) {
			return media.duration > 100 ? 5 : Math.ceil(media.duration * 0.05);
		},

		// resize to media dimensions
		enableAutosize: true,

		// Hide controls when playing and mouse is not over the video
		alwaysShowControls: false,
		noControls: false,
		// Display the video control
		hideVideoControlsOnLoad: false,
		// Enable click video element to toggle play/pause
		clickToPlayPause: true,
		// force iPad's native controls
		iPadUseNativeControls: true,
		// force iPhone's native controls
		iPhoneUseNativeControls: false,
		// force Android's native controls
		AndroidUseNativeControls: false,
		// features to show
		features: ["playpause",
			"current",
			"progress",
			"duration",
			"speed",
			"tracks",
			"soundtracks",
			"videotracks",
			"volume",
			"fullscreen",
			"contextmenu"],
		// only for dynamic
		isVideo: true,

		// turns keyboard support on and off for this instance
		enableKeyboard: true,

		// whenthis player starts, it will pause other players
		pauseOtherPlayers: true,

		bufferingGoal: 50,

		debug: true,

		parent: {},

		// array of keyboard actions such as play pause
		keyActions: [
				{
					keys: [
						32, // SPACE
						179, // GOOGLE play/pause button
						75 // k
					],
					media: ['play', 'pause'],
					action: function(player, media) {
						if (media.paused || media.ended) {
							player.play();
						} else {
							player.pause();
						}
					}
				},
				{
					keys: [38], // UP
					action: function(player, media) {
						if (mejs.PluginDetector.ua.match(/playstation/gi) !== null) player.container.trigger('video.prev');
						else {
							var newVolume = Math.min(media.getVolume() + 0.1, 1);
							media.setVolume(newVolume);
						}
					}
				},
				{
					keys: [40], // DOWN
					action: function(player, media) {
						if (mejs.PluginDetector.ua.match(/playstation/gi) !== null) player.container.trigger('video.next');
						else {
							var newVolume = Math.max(media.getVolume() - 0.1, 0);
							media.setVolume(newVolume);
						}
					}
				},
				{
					keys: [
						37, // LEFT
						227 // Google TV rewind
					],
					media: ['seekbackward'],
					action: function(player) {
						player.seekStep(-1);
					}
				},
				{
					keys: [
						39, // RIGHT
						228 // Google TV forward
					],
					media: ['seekforward'],
					action: function(player) {
						player.seekStep(1);
					}
				},
				{
					keys: [
						74, // j
					],
					action: function(player) {
						player.seekStep(-2);
					}
				},
				{
					keys: [
						76, // l
					],
					action: function(player) {
						player.seekStep(2);
					}
				},
				{
					keys: [
						176 // next
					],
					media: ['nexttrack'],
					action: function(player) {
						player.container.trigger('video.next');
					}
				},
				{
					keys: [
						177 // prev
					],
					media: ['previoustrack'],
					action: function(player) {
						player.container.trigger('video.prev');
					}
				},
				{
					keys: [70], // f
					action: function(player) {
						if (typeof player.enterFullScreen != 'undefined') {
							if (player.isFullScreen) {
								player.exitFullScreen();
							} else {
								player.enterFullScreen();
							}
						}
					}
				},
				{
					keys: [
						13, // enter
						65 // a
					],
					action: function(player, media) {
						if(typeof player.nextTrack != 'undefined') player.nextTrack(media);
					}
				},
				{
					keys: [
						83 // s
					],
					action: function(player) {
						var count = player.tracks.length, sel = player.selectedTrack != null ? player.selectedTrack.index + 1 : '0';
						if(sel > count - 1) sel = 'none';
						$("#mep_0_captions_" + sel).prop('checked', true);
						player.setTrack(sel);
					}
				},
				{
					keys: [
						77, // m
					],
					action: function(player, media) {
						media.setMuted(!media.muted);
					}
				},
				{
					keys: [80], // P
					action: function(player, media) {
						player.container.trigger('video.prev');
					}
				},
				{
					keys: [78], // N
					action: function(player, media) {
						player.container.trigger('video.next');
					}
				},
				{
					keys: [
						48, // 0
						49, 50, 51, 52, 53, 54, 55, 56,
						57 // 9
					],
					action: function(player, media, code) {
						player.seekStep(code - 48, true);
					}
				},
				{
					keys: [
						-188, // <
					],
					action: function(player) {
						player.stepSpeed(-1);
					}
				},
				{
					keys: [
						-190, // >
					],
					action: function(player) {
						player.stepSpeed(1);
					}
				},
				{
					keys: [
						112, // F1
					],
					action: function(player) {
						player.options.parent.displayHotkeys();
					}
				},
		]
	};

	mejs.mepIndex = 0;

	mejs.players = {};

	// wraps a MediaElement object in player controls
	mejs.MediaElementPlayer = function(node, o) {
		// enforce object, even without "new" (via John Resig)
		if ( !(this instanceof mejs.MediaElementPlayer) ) {
			return new mejs.MediaElementPlayer(node, o);
		}

		var t = this;

		// these will be reset after the MediaElement.success fires
		t.$media = t.$node = $(node);
		t.node = t.media = t.$media[0];

		// check for existing player
		if (typeof t.node.player != 'undefined') {
			return t.node.player;
		} else {
			// attach player to DOM node for reference
			t.node.player = t;
		}

		// try to get options from data-mejsoptions
		if (typeof o == 'undefined') {
			o = t.$node.data('mejsoptions');
		}

		// extend default options
		t.options = $.extend({},mejs.MepDefaults,o);
		t.parent = t.options.parent;

		// unique ID
		t.id = 'mep_' + mejs.mepIndex++;

		// add to player array (for focus events)
		mejs.players[t.id] = t;

		// start up
		t.init();

		return t;
	};

	// actual player
	mejs.MediaElementPlayer.prototype = {
		hasFocus: true,
		controlsAreVisible: true,
		attempt: 0,

		init: function() {
			var
				t = this,
				mf = mejs.MediaFeatures,
				// options for MediaElement (shim)
				meOptions = $.extend({}, t.options, {
					success: function(media, domNode) { t.meReady(media, domNode); },
					error: function(e) { t.handleError(e);}
				}),
				tagName = t.media.tagName.toLowerCase();

			t.isDynamic = (tagName !== 'audio' && tagName !== 'video');

			if (t.isDynamic) {
				// get video from src or href?
				t.isVideo = t.options.isVideo;
			} else {
				t.isVideo = (tagName !== 'audio' && t.options.isVideo);
			}

			// use native controls in iPad, iPhone, and Android
			if ((mf.isiPad && t.options.iPadUseNativeControls) || (mf.isiPhone && t.options.iPhoneUseNativeControls)) {
				// add controls and stop
				t.$media.attr('controls', 'controls');

				// override Apple's autoplay override for iPads
				if (mf.isiPad && t.media.getAttribute('autoplay') !== null) {
					t.play();
				}
			} else if (mf.isAndroid && t.options.AndroidUseNativeControls) {
				// leave default player
				t.$media.attr('controls', 'controls');
			} else {
				// DESKTOP: use MediaElementPlayer controls

				// remove native controls
				t.$media.removeAttr('controls');

				// build container
				t.container =
					$('<div id="' + t.id + '" class="mejs-container ' + (mejs.MediaFeatures.svg ? 'svg' : 'no-svg') + '">'+
						'<div class="mejs-inner">'+
							'<div class="mejs-mediaelement"></div>'+
							'<div class="mejs-layers"></div>'+
							'<div class="mejs-controls"></div>'+
							'<div class="mejs-clear"></div>'+
						'</div>' +
					'</div>')
					.addClass(t.$media[0].className)
					.insertBefore(t.$media)
					.on('focus', function() {
						if(!t.controlsAreVisible && t.controlsEnabled) {
							t.showControls(true);
						}
					});

				t.container.find('.mejs-mediaelement').append(t.$media);

				// find parts
				t.controls = t.container.find('.mejs-controls');
				t.layers = t.container.find('.mejs-layers');

				// determine the size

				/* size priority:
					(1) videoWidth (forced),
					(2) style="width;height;"
					(3) width attribute,
					(4) defaultVideoWidth (for unspecified cases)
				*/

				var tagType = (t.isVideo ? 'video' : 'audio'),
					capsTagName = tagType.substring(0,1).toUpperCase() + tagType.substring(1);

				if (t.options[tagType + 'Width'] > 0 || t.options[tagType + 'Width'].toString().indexOf('%') > -1) {
					t.width = t.options[tagType + 'Width'];
				} else if (t.media.style.width !== '' && t.media.style.width !== null) {
					t.width = t.media.style.width;
				} else if (t.media.getAttribute('width') !== null) {
					t.width = t.$media.attr('width');
				} else {
					t.width = t.options['default' + capsTagName + 'Width'];
				}

				if (t.options[tagType + 'Height'] > 0 || t.options[tagType + 'Height'].toString().indexOf('%') > -1) {
					t.height = t.options[tagType + 'Height'];
				} else if (t.media.style.height !== '' && t.media.style.height !== null) {
					t.height = t.media.style.height;
				} else if (t.$media[0].getAttribute('height') !== null) {
					t.height = t.$media.attr('height');
				} else {
					t.height = t.options['default' + capsTagName + 'Height'];
				}

				// set the size, while we wait for the plugins to load below
				t.setPlayerSize(t.width, t.height);
				// create MediaElementShim
				meOptions.pluginWidth = t.width;
				meOptions.pluginHeight = t.height;
			}

			// create MediaElement shim
			mejs.MediaElement(t.$media[0], meOptions);

			if (typeof(t.container) != 'undefined' && t.controlsAreVisible){
				// controls are shown when loaded
				t.container.trigger('controlsshown');
			}
		},

		showControls: function(doAnimation) {
			var t = this;

			if (t.controlsAreVisible || t.options.noControls)
				return;

			doAnimation = typeof doAnimation == 'undefined' || doAnimation;

			t.controls.removeClass('mejs-hidden').css('visibility','visible');
			// hide others
			t.container.find('.mejs-control').css('visibility','visible');
			t.controlsAreVisible = true;
			t.container.trigger('controlsshown');

			t.setControlsSize();

			t.updateCurrent();
		},

		hideControls: function(doAnimation) {
			var t = this;

			doAnimation = typeof doAnimation == 'undefined' || doAnimation;

			if (!t.controlsAreVisible || t.options.alwaysShowControls || !t.controls)
				return;

			if (t.media && t.media.paused && !t.adsPlayerHasStarted && !t.options.noControls) {
				if (t.controlsTimer == null) {
					t.startControlsTimer();
				}
				return;
			}

			if (doAnimation) t.controls.addClass('mejs-hidden');
			else t.controls.css('visibility','hidden');

			// hide others
			t.container.find('.mejs-control').css('visibility','hidden');
			t.controlsAreVisible = false;
			t.container.trigger('controlshidden');
		},

		seekStep: function(sec, abs) {
			var t = this;
			if (!isNaN(t.media.duration) && t.media.duration > 0) {
				if (t.isVideo) {
					t.showControls();
					t.startControlsTimer();
				}

				if (typeof abs == 'undefined') {
					var diff = t.options.defaultSeekForwardInterval(t.media) * sec, newTime = t.media.currentTime + diff;
				} else {
					var diff = sec > 0 ? (sec * 10) + "%" : "начало", newTime = t.media.duration * sec / 10;
				}

				if (newTime > t.media.duration) newTime = t.media.duration;
				else if(newTime < 0) newTime = 0;
				t.setCurrentTime(newTime);
				t.setMessage(sec < 0 ? "<img src='/image/player/rewind.svg' width=40 height=40> " + -diff : diff + " <img src='/image/player/ff.svg' width=40 height=40>");
			}

		},

		controlsTimer: null,

		startControlsTimer: function(timeout) {
			var t = this;

			timeout = typeof timeout != 'undefined' ? timeout : 1500;

			t.killControlsTimer('start');

			t.controlsTimer = setTimeout(function() {
				t.killControlsTimer('hide');
				t.hideControls();
			}, timeout);
		},

		killControlsTimer: function(src) {
			var t = this;

			if (t.controlsTimer !== null) {
				clearTimeout(t.controlsTimer);
				delete t.controlsTimer;
				t.controlsTimer = null;
			}
		},

		controlsEnabled: true,

		disableControls: function() {
			var t = this;

			t.killControlsTimer("disableControls");
			t.hideControls(false);
			this.controlsEnabled = false;
		},

		enableControls: function() {
			var t = this;

			t.showControls(false);

			t.controlsEnabled = true;
		},

		reinitControls: function() {
			var t = this;
			t.killControlsTimer("reinitControls");
			t.hideControls(false);
			t.controls.empty();

			for (let featureIndex in t.options.features) {
				let feature = t.options.features[featureIndex];
				if (t['build' + feature]) {
					try {
						t['build' + feature](t, t.controls, t.layers, t.media);
					} catch (e) {
						console.error(e);
					}
				}
			}

			if (t.captions && !t.captions.hasClass('mejs-captions-layer') && t.selectedTrack && typeof t.selectedTrack.entries.ass == 'object') {
				t.selectedTrack.entries.ass.dispose();
			}

			t.enableControls();
			t.setControlsSize();
			//t.bigPlay.show();
			t.startControlsTimer(2500);
		},

		// Sets up all controls and events
		meReady: function(media, domNode) {
			var t = this,
				mf = mejs.MediaFeatures,
				autoplayAttr = domNode.getAttribute('autoplay'),
				autoplay = !(typeof autoplayAttr == 'undefined' || autoplayAttr === null || autoplayAttr === 'false'),
				featureIndex,
				feature;

			// make sure it can't create itself again if a plugin reloads
			if (t.created) {
				return;
			} else {
				t.created = true;
			}

			t.media = media;
			t.domNode = domNode;
			if (!(mf.isAndroid && t.options.AndroidUseNativeControls) && !(mf.isiPad && t.options.iPadUseNativeControls) && !(mf.isiPhone && t.options.iPhoneUseNativeControls)) {
				// two built in features
				t.buildposter(t, t.controls, t.layers, t.media);
				t.buildkeyboard(t, t.controls, t.layers, t.media);
				t.buildoverlays(t, t.controls, t.layers, t.media);

				// grab for use by features
				t.setTracks(t.options.tracks);

				// add user-defined features/controls
				for (featureIndex in t.options.features) {
					feature = t.options.features[featureIndex];
					if (t['build' + feature]) {
						try {
							t['build' + feature](t, t.controls, t.layers, t.media);
						} catch (e) {
							console.error(e);
						}
					}
				}

				t.container.trigger('controlsready');

				// reset all layers and controls
				t.setPlayerSize(t.width, t.height);
				t.setControlsSize();

				// controls fade
				if (t.isVideo) {
					// for touch devices (iOS, Android)
					// show/hide without animation on touch
					var doubleTimer = false, touch_count = 0;
					var onTouch = function(e) {
						doubleTimer = false;
						if (touch_count == 1) {
							// toggle controls
							if (t.controlsAreVisible) {
								t.hideControls(false);
							} else {
								if (t.controlsEnabled) {
									t.bigPlay.addClass('pause').show();
									t.showControls(false);
								}
							}
						} else {
							var x = e.touches[0].pageX, center = parseInt(t.container.offset().left + t.container.width() * 0.5), sec = (touch_count - 1) * 2;
							t.seekStep(x > center ? sec : -sec);
						}
						touch_count = 0;
					};
					t.media.addEventListener('touchstart', function(e) {
						touch_count++;
						if (doubleTimer) clearTimeout(doubleTimer);
						doubleTimer = setTimeout(function() {onTouch(e);}, 400);

						//prevent fire click event
						e.preventDefault();
					});

					// create callback here since it needs access to current
					// MediaElement object
					mejs.MediaElementPlayer.prototype.clickToPlayPauseCallback = function() {
						if (t.options.clickToPlayPause) {
							if (t.media.paused) {
								t.play();
							} else {
								if(!mejs.MediaFeatures.hasTouch) t.pause();
							}
						}
					};

					// click to play/pause
					t.media.addEventListener('click', t.clickToPlayPauseCallback);

					/*mejs.MediaElementPlayer.prototype.dblclickToFullScreenCallback = function() {
						if (typeof media.player.enterFullScreen != 'undefined') {
							if (media.player.isFullScreen) media.player.exitFullScreen();
							else media.player.enterFullScreen();
						}
					};

					t.media.addEventListener('dblclick', t.dblclickToFullScreenCallback);*/

					// show/hide controls
					t.container
						.on('mouseenter', function () {
							if (t.controlsEnabled) {
								if (!t.options.alwaysShowControls) {
									t.killControlsTimer('enter');
									t.showControls();
									t.startControlsTimer(2500);
								}
							}
						})
						.on('mousemove', throttle(function() {
							if (t.controlsEnabled) {
								if (!t.controlsAreVisible) {
									t.showControls();
								}
								//t.killControlsTimer('move');
								if (!t.options.alwaysShowControls) {
									t.startControlsTimer(2500);
								}
							}
						}, 500))
						.on('mouseleave', function () {
							if (t.controlsEnabled) {
								if (!t.media.paused && !t.options.alwaysShowControls) {
									t.startControlsTimer(1000);
								}
							}
						});

					t.container.on('click', '.mejs-button button', function() {
						var selector = $(this).siblings("div");
						if (selector.css("visibility") == "hidden") {
							$(".mejs-selector").css("visibility", "hidden");
							selector.css("visibility", "visible");
						} else selector.css("visibility", "hidden");
					});
					t.container.on('click', '.mejs-button div li', function() {
						$(this).parent().parent().css("visibility", "hidden");
					});
					t.container.on('mouseenter mouseleave', '.mejs-button', function(e) {
						if (mejs.MediaFeatures.hasTouch) return;
						var selector = $(this).find("div");
						if (e.type == 'mouseenter' || e.type == 'mouseover' || e.type == 'touchstart') selector.css("visibility", "visible");
						else selector.css("visibility", "hidden");
					});

					if(t.options.hideVideoControlsOnLoad) {
						t.hideControls(false);
					}

					// check for autoplay
					if (autoplay && !t.options.alwaysShowControls) {
						t.hideControls();
					}

					// resizer
					if (t.options.enableAutosize) {
						t.media.addEventListener('loadedmetadata', function(e) {
							// if the <video height> was not set and the options.videoHeight was not set
							// then resize to the real dimensions
							if (t.options.videoHeight <= 0 && t.domNode.getAttribute('height') === null && !isNaN(e.target.videoHeight)) {
								t.setPlayerResize(e.target.videoWidth, e.target.videoHeight);
							}
						}, false);
					}
				}

				// EVENTS

				// FOCUS: when a video starts playing, it takes focus from other players (possibily pausing them)
				media.addEventListener('play', function() {
					var playerIndex;

					// go through all other players
					for (playerIndex in mejs.players) {
						var p = mejs.players[playerIndex];
						if (p.id != t.id && t.options.pauseOtherPlayers && !p.paused && !p.ended) {
							p.pause();
						}
						p.hasFocus = false;
					}

					t.hasFocus = true;
				},false);

				// ended for all
				t.media.addEventListener('ended', function () {
					t.pause();

					if (t.setProgressRail) {
						t.setProgressRail();
					}
					if (t.setCurrentRail) {
						t.setCurrentRail();
					}

					if (!t.options.alwaysShowControls && t.controlsEnabled && !t.isFullScreen) {
						t.showControls();
					}
					t.container.trigger('video.next', [1]);
				}, false);

				// resize on the first play
				t.media.addEventListener('loadedmetadata', function() {
					if (t.updateDuration) {
						t.updateDuration();
					}
					if (t.updateCurrent) {
						t.updateCurrent();
					}

					if (!t.isFullScreen) {
						t.setPlayerSize(t.width, t.height);
						t.setControlsSize();
					}
				}, false);


				// webkit has trouble doing this without a delay
				setTimeout(function () {
					t.setPlayerSize(t.width, t.height);
					t.setControlsSize();
				}, 50);

				// adjust controls whenever window sizes (used to be in fullscreen only)
				t.globalBind('resize', function() {
					// don't resize for fullscreen mode
					if (!t.isFullScreen) {
						t.setPlayerSize(t.width, t.height);
					}

					// always adjust controls
					t.setControlsSize();
				});

				// This is a work-around for a bug in the YouTube iFrame player, which means
				//  we can't use the play() API for the initial playback on iOS or Android;
				//  user has to start playback directly by tapping on the iFrame.
				if (t.media.pluginType == 'youtube' && (mf.isiOS || mf.isAndroid)) {
					t.container.find('.mejs-overlay-play').hide();
					t.container.find('.mejs-poster').hide();
				}
			}

			// force autoplay for HTML5
			if (autoplay && media.pluginType == 'native') {
				media.load();
				media.play();
			}

			if (t.options.success) {
				if (typeof t.options.success == 'string') {
					window[t.options.success](t.media, t.domNode, t);
				} else {
					t.options.success(t.media, t.domNode, t);
				}
			}
		},

		handleError: function(e) {
			var t = this;

			if(t.controls) t.controls.hide();

			// Tell user that the file cannot be played
			if (t.options.error) {
				t.options.error(e);
			}
		},

		setPlayerResize: function(width, height) {
			var t = this, mf = mejs.MediaFeatures;
			if (!(mf.isAndroid && t.options.AndroidUseNativeControls) && !(mf.isiPad && t.options.iPadUseNativeControls) && !(mf.isiPhone && t.options.iPhoneUseNativeControls)) {
				t.setPlayerSize(width, height);
				t.setControlsSize();
				//t.media.setVideoSize(width, height); // resize of flash fallback
			}
		},

		setPlayerSize: function(width,height) {
			if(this.selectedTrack && typeof this.selectedTrack.entries.ass == 'object')
				this.selectedTrack.entries.ass.resize();
		},

		setControlsSize: function() {
			this.container.trigger('controlsresize');
		},

		buildposter: function(player, controls, layers, media) {
			var t = this,
				poster =
				$('<div class="mejs-poster mejs-layer">' +
				'</div>')
					.appendTo(layers),
				posterUrl = player.$media.attr('poster');


			// prioriy goes to option (this is useful if you need to support iOS 3.x (iOS completely fails with poster)
			if (player.options.poster !== '') {
				posterUrl = player.options.poster;
			}

			// second, try the real poster
			if (posterUrl !== '' && posterUrl != null) {
				t.setPoster(posterUrl);
			} else {
				poster.hide();
			}

			// poster should always be visible on iPhone
			// since the <video> element is being "hidden"
			if (!mejs.MediaFeatures.isiPhone) {
				media.addEventListener('play',function() {
					poster.hide();
				}, false);
			}
		},

		setPoster: function(url) {
			var t = this,
				posterDiv = t.container.find('.mejs-poster'),
				posterImg = posterDiv.find('img');

			if (posterImg.length == 0) {
				posterImg = $('<img width="100%" height="100%" />').appendTo(posterDiv);
			}

			posterImg.attr('src', url);
			posterDiv.css({'background-image' : 'url(' + url + ')'});
		},

		buildoverlays: function(player, controls, layers, media) {
			var t = this;
			if (!player.isVideo)
				return;
			var
			loading =
				$('<div class="mejs-overlay mejs-layer">'+
					'<div class="mejs-overlay-loading"><span class="animate-spin"></span></div>'+
				'</div>')
				.hide() // start out hidden
				.appendTo(layers),
			error =
				$('<div class="mejs-overlay mejs-layer">'+
					'<div class="mejs-overlay-error"></div>'+
				'</div>')
				.hide() // start out hidden
				.appendTo(layers),
			// this needs to come last so it's on top
			bigPlay =
				$('<div class="mejs-overlay mejs-layer mejs-overlay-play">'+
					'<div class="mejs-overlay-top"><div class="title"></div></div>'+
					'<div class="mejs-overlay-button"></div>'+
				'</div>')
				.appendTo(layers)
				.on('click', function(e) {
					if (t.options.clickToPlayPause) {
						if (media.paused) {
							t.play();
						} else {
							if ($(e.target).hasClass('mejs-overlay-button')) {
								t.pause();
							} else {
								$(this).hide();
								t.hideControls(false);
							}
							$(this).removeClass('pause');
						}
					}
				}),
			message =
				$('<div class="mejs-overlay mejs-layer">'+
					'<div class="mejs-overlay-message"></div>'+
				'</div>')
				.hide() // start out hidden
				.appendTo(layers);

			t.loading_hide = function() {
				loading.hide();
				controls.find('.mejs-time-buffering').hide();
				t.loading_timeout = false;
				if(t.loading_progess) {
					clearInterval(t.loading_progess);
					t.loading_progess = false;
					if(t.circleProgressBar) t.circleProgressBar.setValue(0);
				}
			};
			t.loading_show = function() {
				if(!t.messageTimer) {loading.show(); controls.find('.mejs-time-buffering').show();}
				t.loading_timeout = true;
				// todo
				/*if(t.media && t.media.dash_player && !t.loading_progess && t.options.debug) {
					if(loading.find("span").length) {
						// or https://daverupert.com/2018/03/animated-svg-radial-progress-bars/
						$(".mejs-overlay-loading").html("<canvas id='canvas' width='80' height='80'></canvas><div id='canvas-data'></div>");
						nbl.l('/resource/js/circle-progress-bar.min.js', function() {
							var canvas = document.getElementById('canvas');
							t.circleProgressBar = new CircleProgressBar(canvas, {
								radius: 35, lineWidth: 4, colors: ['#79808c', '#49576df2', '#49576df2']
							});
						});
					}
					t.loading_progess = setInterval(function() {
						if(typeof t.circleProgressBar != 'undefined') {
							t.circleProgressBar.setValue(mejs.DashApi.progress * 0.01);
							//$("#canvas-data").html((mejs.DashApi.progress).toFixed(0) + '%');
						}
					}, 200);
				}*/
			};
			t.onPlay = function(hide_loading) {
				bigPlay.hide();
				if(typeof hide_loading == 'object') {
					t.loading_hide();
					error.hide();
				} else {loading.show(); controls.find('.mejs-time-buffering').show();}
			}

			t.bigPlay = bigPlay;
			t.message = message;

			// show/hide big play button
			media.addEventListener('play', t.onPlay, false);
			media.addEventListener('playing', t.onPlay, false);

			if (t.media.pluginType !== 'native') {
				media.addEventListener('click',function() {
					if (t.options.clickToPlayPause && t.isFullScreen) {
						if (media.paused) t.play();
						else t.pause();
					}
				}, false);
			}

			// on iPhone, show bigPlay after user quits video
			if (mejs.MediaFeatures.isiPhone) {
				media.addEventListener('webkitendfullscreen', function() {
					bigPlay.show();
				}, false);
			}

			media.addEventListener('seeking', t.loading_show, false);
			media.addEventListener('seeked', t.loading_hide, false);

			media.addEventListener('pause', function() {
				if (!mejs.MediaFeatures.isiPhone) {
					bigPlay.show();
				}
			}, false);

			media.addEventListener('waiting', t.loading_show, false);

			// show/hide loading
			media.addEventListener('canplay', t.loading_hide, false);
			media.addEventListener('loadeddata', function() {t.isLoaded = true; t.loading_hide();}, false);

			// error handling
			media.addEventListener('error',function(e) {
				if(e.target.error == null) return; // preview error
				t.handleError(e);
				bigPlay.hide();
				t.loading_hide();
				error.show();
				var text = "";
				if(e.target.error) {
					switch (e.target.error.code) {
						case e.target.error.MEDIA_ERR_ABORTED: text = "Вы прекратили воспроизведение"; break;
						case e.target.error.MEDIA_ERR_NETWORK:
						text = "Ошибка соединения";
						break;
						case e.target.error.MEDIA_ERR_DECODE:
						case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
						text = "Ошибка" + (typeof e.target.error.message != 'undefined' ? ": " + e.target.error.message : '');
						break;
						default: text = "Извините, у нас временные неполадки"; break;
					}
				}
				error.find('.mejs-overlay-error').html(text);
			}, false);
		},

		buildkeyboard: function(player, controls, layers, media) {
				var t = this;

				// listen for key presses
				t.globalBind('keydown', function(e) {
						return t.onkeydown(player, media, e);
				});

				// check if someone clicked outside a player region, then kill its focus and vise versa
				t.globalBind('click', function(event) {
					if($(event.target).closest('.mejs-container').length != 0) player.hasFocus = true;
				});
		},

		onkeydown: function(player, media, e) {
			if (player.hasFocus && player.options.enableKeyboard) {
				let keyCode = e.keyCode;
				if (e.shiftKey) keyCode = -e.keyCode;
				// find a matching key
				for (var i = 0, il = player.options.keyActions.length; i < il; i++) {
					var keyAction = player.options.keyActions[i];

					for (var j = 0, jl = keyAction.keys.length; j < jl; j++) {
						if (keyCode == keyAction.keys[j]) {
							if (typeof(e.preventDefault) == "function") e.preventDefault();
							keyAction.action(player, media, keyCode);
							return false;
						}
					}
				}
			}

			return true;
		},

		setTracks: function(tracks) {
			var t = this;
			t.tracks = [];
			for (var i in tracks) {
				var track = tracks[i];
				t.tracks.push({
					src: track.src,
					kind: 'subtitles',
					label: track.label,
					default: track.default,
					entries: [],
					isLoaded: false
				});
			}
		},
		play: function() {
			var t = this;
			if (t.media.dash_player) {
				t.media.dash_player.configure({
					streaming: {
						bufferingGoal: t.options.bufferingGoal
					}
				});
				t.load().then(function () { t.media.play(); }, function (err) { alert("err"); console.error(err); t.media.play(); });
				t.onPlay();
				t.loading_show();
			} else {
				t.load();
				t.media.play();
			}
		},
		pause: function() {
			try {
				this.media.pause();
				if (this.media.dash_player) {
					this.media.dash_player.configure                                                         ({
						streaming: {
							bufferingGoal: this.options.bufferingGoal * 3
						}
					});
				}
			} catch (e) {console.error(e);}
		},
		load: function() {
			var t = this;
			return new Promise(function(resolve, reject) {
				if (!t.isLoaded) {
					t.isLoaded = true;
					if (t.media.dash_player) {
						if (t.src) {
							var src = t.src;
							if (mejs.DashApi.offline_content) src = mejs.DashApi.offline_content.offlineUri;

							//shaka.log.setLevel(shaka.log.Level.V2);
							t.media.dash_player.load(src, t.currentTime).then(function() {
								resolve();
								//t.src = false;
								//console.log('The video has now been loaded!');
							}).catch(function(error) {
								console.error(error);
								t.media.dash_player.destroy().then(function() {t.media.dash_player = 0; t.setSrc(t.src2); t.media.play(); resolve();});
							});
						} else reject("no src");
						return;
					}
					t.media.load();

					var onLoad = function() {
						t.media.removeEventListener('loadedmetadata', onLoad, false);
						resolve();
					};
					if (t.media.readyState >= 2) resolve();
					else t.media.addEventListener('loadedmetadata', onLoad, false);
				} else resolve();
			});
		},
		setMuted: function(muted) {
			this.media.setMuted(muted);
		},
		setCurrentTime: function(time) {
			this.currentTime = time;
			this.media.setCurrentTime(time);
			this.setCurrentRail();
		},
		getCurrentTime: function() {
			return this.media.currentTime;
		},
		setVolume: function(volume) {
			this.media.setVolume(volume);
		},
		getVolume: function() {
			return this.media.getVolume();
		},
		setSrcExec: function(src, src2, track) {
			var t = this;

			t.src = src;
			t.src2 = src2;
			if (t.media.dash_player) {
				if (track) {
					$("#mep_0_soundtrack_" + track).prop("checked", true);
					t.audioTrack = track;
				}

				t.media.dash_player.configure({
					preferredAudioLanguage: 'au' + (t.audioTrack ? t.audioTrack : 1),
					streaming: {
						bufferBehind: 60,
						bufferingGoal: t.options.bufferingGoal,
						rebufferingGoal: 2
					},
					abr: { enabled: false }
				});
				mejs.DashApi.offlineInit(t.media.dash_player, t.src);
			} else if(src.indexOf(".m3u8") != -1) t.media.setSrc(src);
			else {
				t.media.setSrc(src.indexOf(".mpd") != -1 ? src2 : src);
			}
		},
		setSrc: function(src, src2, track, title) {
			var t = this;

			$('.mejs-overlay-top .title').html(title);

			return new Promise(function(resolve, reject) {
				if (!src) return reject("no src");
				t.isLoaded = false;
				t.lastPos = 0;
				if (!t.paused) { t.pause(); /*t.lastPos = t.getCurrentTime();*/ }
				if (t.media.dash_player) {
					if (t.src && src.indexOf(".mpd") == -1) {
						t.media.dash_player.destroy().then(function() {
							t.media.dash_player = false;
							t.setSrcExec(src, src2, track);
							resolve();
						});
						return;
					}
				} else if (src.indexOf(".mpd") != -1)
					t.media.dash_player = mejs.DashApi.init(t.media);
				t.setSrcExec(src, src2, track);
				resolve();
			});
		},
		remove: function() {
			var t = this, featureIndex, feature;

			// invoke features cleanup
			for (featureIndex in t.options.features) {
				feature = t.options.features[featureIndex];
				if (t['clean' + feature]) {
					try {
						t['clean' + feature](t);
					} catch (e) {
						console.log(feature, e);
					}
				}
			}
			let cleanup = function() {
				t.media.pause(); t.media.src = ""; t.media.load(); delete t.media; t.$media.remove();
			}

			if (t.media.dash_player) t.media.dash_player.destroy().then(cleanup);
			else cleanup();

			delete mejs.players[t.id];

			if (typeof t.container == 'object') {
				t.container.remove();
			}
			t.globalUnbind();
			delete t.node.player;
			t.$node.remove();
		},
		setMessage: function(text, timeout) {
			var t = this;
			if (typeof timeout == 'undefined') timeout = 1000;
			t.message.show().find("div").html(text);
			if (t.messageTimer) clearTimeout(t.messageTimer);
			if (timeout) {
				t.messageTimer = setTimeout(function () {
					t.hideMessage();
				}, timeout);
			}
			if (t.loading_timeout) { t.loading_hide(); t.loading_timeout = true; }
		},
		hideMessage: function() {
			var t = this;
			t.message.hide();
			if (t.messageTimer) { clearTimeout(t.messageTimer); t.messageTimer = 0; }
			if (t.loading_timeout) t.loading_show();
		}
	};

	(function(){
		var rwindow = /^((after|before)print|(before)?unload|hashchange|message|o(ff|n)line|page(hide|show)|popstate|resize|storages)\b/;
		var all_events = {};

		function splitEvents(events, id, push) {
			// add player ID as an event namespace so it's easier to unbind them all later
			var ret = {d: [], w: []};
			$.each((events || '').split(' '), function(k, v){
				if (!v) return;
				var eventname = v + '.' + id;
				if (push) {
					if (!all_events[v]) all_events[v] = 1;
					else return;
				} else {
					if (all_events[v]) delete all_events[v];
					else return;
				}
				if (eventname.indexOf('.') === 0) {
					ret.d.push(eventname);
					ret.w.push(eventname);
				}
				else {
					ret[rwindow.test(v) ? 'w' : 'd'].push(eventname);
				}
			});
			ret.d = ret.d.join(' ');
			ret.w = ret.w.join(' ');
			return ret;
		}

		mejs.MediaElementPlayer.prototype.globalBind = function(events, data, callback) {
			var t = this;
			events = splitEvents(events, t.id, 1);
			if (events.d) $(document).on(events.d, data, callback);
			if (events.w) $(window).on(events.w, data, callback);
		};

		mejs.MediaElementPlayer.prototype.globalUnbind = function(events, callback) {
			var t = this;
			if (!events) {
				events = '';
				for (let event in all_events) {
					events += event + ' ';
				}
			}
			events = splitEvents(events, t.id, 0);

			if (events.d) $(document).off(events.d, callback);
			if (events.w) $(window).off(events.w, callback);
		};
	})();


	// turn into jQuery plugin
	if (typeof $ != 'undefined') {
		$.fn.mediaelementplayer = function (options) {
			if (options === false) {
				this.each(function () {
					var player = $(this).data('mediaelementplayer');
					if (player) {
						player.remove();
					}
					$(this).removeData('mediaelementplayer');
				});
			}
			else {
				this.each(function () {
					$(this).data('mediaelementplayer', new mejs.MediaElementPlayer(this, options));
				});
			}
			return this;
		};
	}

	// push out to window
	window.MediaElementPlayer = mejs.MediaElementPlayer;

})(mejs.$);

(function($) {

	$.extend(mejs.MepDefaults, {
		playpauseText: "Воспроизведение/Пауза (k)"
	});

	// PLAY/pause BUTTON
	$.extend(mejs.MediaElementPlayer.prototype, {
		buildplaypause: function(player, controls, layers, media) {
			var t = this,
				play = $('<div class="mejs-button mejs-playpause-button mejs-play" >' +
					'<button type="button" aria-controls="' + t.id + '" title="' + t.options.playpauseText + '" aria-label="' + t.options.playpauseText + '"></button>' +
				'</div>')
				.appendTo(controls)
				.on('click', function(e) {
					e.preventDefault();

					if (media.paused) {
						t.play();
					} else {
						t.pause();
					}

					return false;
				});

			media.addEventListener('play',function() {
				play.removeClass('mejs-play').addClass('mejs-pause');
			}, false);
			media.addEventListener('playing',function() {
				play.removeClass('mejs-play').addClass('mejs-pause');
			}, false);

			media.addEventListener('pause',function() {
				play.removeClass('mejs-pause').addClass('mejs-play');
			}, false);
			media.addEventListener('paused',function() {
				play.removeClass('mejs-pause').addClass('mejs-play');
			}, false);
		}
	});

})(mejs.$);

(function($) {
	// progress/loaded bar
	$.extend(mejs.MediaElementPlayer.prototype, {
		buildprogress: function(player, controls, layers, media) {
			$('<div class="mejs-time-rail">' +
				'<span class="mejs-time-total">' +
					'<span class="mejs-time-buffering"></span>' +
					'<span class="mejs-time-loaded"></span>' +
					'<span class="mejs-time-current"></span>' +
					'<span class="mejs-time-handle"></span>' +
					'<span class="mejs-time-float">' +
						'<span class="mejs-time-float-current">00:00</span>' +
					'</span>' +
					'<span class="mejs-time-float-corner"></span>' +
				'</span>' +
			'</div>').appendTo(controls);
			controls.find('.mejs-time-buffering').hide();

			let t = this,
				total = controls.find('.mejs-time-total'),
				loaded = controls.find('.mejs-time-loaded'),
				current = controls.find('.mejs-time-current'),
				handle = controls.find('.mejs-time-handle'),
				timefloat = controls.find('.mejs-time-float'),
				timefloatcurrent  = controls.find('.mejs-time-float-current'),
				timefloatcorner  = controls.find('.mejs-time-float-corner'),
				handleMouseMove = function(e, touch) {
					// mouse position relative to the object
					var x = 0,
						offset = total.offset(),
						width = total.outerWidth(false),
						percentage = 0,
						newTime = 0,
						pos = 0,
						duration = media.duration ? media.duration : t.options.duration;

					if (e.originalEvent && e.originalEvent.changedTouches) {
						x = e.originalEvent.changedTouches[0].pageX;
					} else if (e.changedTouches) {
						x = e.changedTouches[0].pageX;
					} else {
						x = e.pageX;
					}

					if (duration) {
						if (x < offset.left) {
							x = offset.left;
						} else if (x > width + offset.left) {
							x = width + offset.left;
						}

						pos = x - offset.left;
						percentage = pos / width;
						newTime = percentage < 0 ? 0 : percentage * duration;

						// seek to where the mouse is
						const seek = (touch && !mouseIsDown) || (!touch && mouseIsDown);
						if (seek && t.getCurrentTime() !== null && newTime.toFixed(4) !== media.currentTime.toFixed(4) && !isNaN(newTime)) {
							t.setCurrentTime(newTime);
						} else if(mejs.MediaFeatures.hasTouch) t.drawCurrentRail(newTime);

						// position floating time box
						if (pos > width - 5) pos = width - 5;
						timefloatcorner.css('left', pos);
						if (pos < 30) pos = 30;
						else if (pos > width - 30) pos = width - 30;
						timefloat.css('left', pos);
						const time = mejs.Utility.secondsToTimeCode(newTime);
						timefloatcurrent.html(time);
						timefloat.show();
						timefloatcorner.show();
						if(mejs.MediaFeatures.hasTouch) t.setMessage(time, 1000);
					}
				},
				mouseIsDown = false,
				mouseIsOver = false;

			t.markSkipLayer = '';
			t.mark = false;

			// handle clicks
			total.parent()
				.on('mousedown touchstart', function (e) {
					// only handle left clicks or touch
					var touch = e.which === 1 ? 0 : 1;
					if (e.which === 1 || e.which === 0) {
						mouseIsDown = true;
						t.killControlsTimer('mousedown');
						handleMouseMove(e, touch);
						/*t.globalBind('mousemove.dur touchmove.dur', function(e) {
							console.log('mousemove.dur');
							handleMouseMove(e);
						});*/
						total.on('touchmove', function(event) {handleMouseMove(event, touch);});
						t.globalBind('mouseup.dur touchend.dur', function (e) {
							var target = $(e.target);

							mouseIsDown = false;
							t.startControlsTimer();

							if(target == total || target.closest(total).length) handleMouseMove(e, touch);
							timefloat.hide();
							timefloatcorner.hide();
							t.globalUnbind('mouseup.dur touchend.dur');
						});
					}
				})
				.on('mouseenter', function() {
					mouseIsOver = true;
					t.killControlsTimer('mouseenter');
					t.globalBind('mousemove.dur', function(e) {
						handleMouseMove(e, 0);
					});
					timefloat.show();
					timefloatcorner.show();
				})
				.on('mouseleave',function() {
					mouseIsOver = false;
					t.startControlsTimer();
					if (!mouseIsDown) {
						timefloat.hide();
						timefloatcorner.hide();
						t.globalUnbind('mousemove.dur mouseup.dur touchend.dur');
					}
				});

			t.initMark();

			// loading
			media.addEventListener('progress', function (e) {
				player.setProgressRail(e);
			}, false);

			// current time
			media.addEventListener('timeupdate', function(e) {
				player.setCurrentRail();
			}, false);

			t.container.on('controlsresize', function() {
				player.setProgressRail();
				player.setCurrentRail();
			});

			// store for later use
			t.loaded = loaded;
			t.total = total;
			t.current = current;
			t.handle = handle;
		},
		setProgressRail: function(e) {
			var
				t = this,
				target = (e != undefined) ? e.target : t.media,
				width_of_second = t.total.width() / t.options.duration,
				percent = null;
			if (!t.controlsAreVisible) return;

			// newest HTML5 spec has buffered array (FF4, Webkit)
			if (target && target.buffered && target.buffered.length > 0 && target.buffered.end && target.duration) {
				if (t.buffers != target.buffered.length) {
					if (t.buffers > target.buffered.length) {
						for (let i = target.buffered.length; i < t.buffers; i++)
							t.total.find("#buffer-" + i).remove();
					}
					t.buffers = target.buffered.length;
				}
				try {
					for (let i = 0; i < target.buffered.length; i++) {
						var id = "buffer-" + i,
							start = Math.round(width_of_second * target.buffered.start(i)),
							width = Math.round(width_of_second * target.buffered.end(i)) - start,
							buffer = t.total.find("#" + id);
						if (start + width > t.total.width()) { width = t.total.width() - start; } // sanity check

						if (!buffer.length) {
							buffer = $("<span id='" + id + "' class='mejs-time-loaded'></span>").appendTo(t.total);
						}

						if (buffer.width() != width) buffer.width(width);
						if (buffer.css("left") != start + "px") buffer.css("left", start);
					}
				} catch (e) { console.log(e); }
				return;
			} else if (target && target.bytesTotal != undefined && target.bytesTotal > 0 && target.bufferedBytes != undefined) {
				percent = target.bufferedBytes / target.bytesTotal;
			}
			// Firefox 3 with an Ogg file seems to go this way
			else if (e && e.lengthComputable && e.total != 0) {
				percent = e.loaded / e.total;
			}

			const marks = t.options.parent.video.extra.marks;
			for (let i in marks) {
				const id = "mark-" + i,
					start = Math.round(width_of_second * marks[i].t);
				let el = t.total.find("#" + id);

				if (!el.length) {
					el = $("<span id='" + id + "' class='mark'></span>").appendTo(t.total);
				}

				el.css("left", start);
			}

			// finally update the progress bar
			if (percent !== null) {
				percent = Math.min(1, Math.max(0, percent));
				// update loaded bar
				if (t.loaded && t.total) {
					t.loaded.width(t.total.width() * percent);
				}
			}
		},
		drawCurrentRail: function(newTime) {
			var t = this, newWidth = Math.round(t.total.width() * newTime / t.media.duration),
			handlePos = newWidth - Math.round(t.handle.outerWidth(true) / 2);

			t.current.width(newWidth + 4);
			t.handle.css('left', handlePos);
			var time = mejs.Utility.secondsToTimeCode(newTime);
			t.currenttime.html(time);
		},
		setCurrentRail: function() {
			var t = this;

			if (t.media && t.mark && t.media.currentTime > 5) t.hideMark();

			if (t.media && t.controlsAreVisible && t.media.currentTime != undefined && t.media.duration) {
				// update bar and handle
				if (t.total && t.handle) {
					t.drawCurrentRail(t.media.currentTime);
				}
			}
		},
		hideMark: function() {
			var t = this;
			t.mark = false;
			if (t.markSkipLayer) t.markSkipLayer.hide();
		},
		toMark: function(e) {
			var t = this;
			if (!t.mark) return;
			t.setCurrentTime(t.mark.t);
			t.hideMark();
			t.play();
		},
		initMark: function() {
			var t = this;
			if (t.options.parent.marks) {
				for (let i in t.options.parent.marks) {
					const mark0 = t.options.parent.marks[i];
					if (mark0.s == 'b' && mark0.t > 10) t.mark = mark0;
				}
				if (!t.mark && t.options.parent.marks[0].t > 10) {
					t.mark = t.options.parent.marks[0];
				}
				if (t.mark) {
					if (!t.markSkipLayer) {
						t.markSkipLayer =
								$('<div class="marks-skip-block">' +
									'<span class="marks-skip-button">Перейти к "' + t.mark.c + '"</span>' +
								'</div>').insertAfter(t.layers.find('.mejs-overlay-play'));
						t.markSkipLayer.find('.mark-skip-button').on('click', t.toMark.bind(t));
					}
				}
			}
		}
	});
})(mejs.$);

(function($) {

	// options
	$.extend(mejs.MepDefaults, {
		duration: -1,
		timeAndDurationSeparator: '<span> | </span>'
	});


	// current and duration 00:00 / 00:00
	$.extend(mejs.MediaElementPlayer.prototype, {
		buildcurrent: function(player, controls, layers, media) {
			var t = this;

			$('<div class="mejs-time start">'+
					'<span class="mejs-currenttime">00:00</span>'+
					'</div>')
					.appendTo(controls);

			t.currenttime = t.controls.find('.mejs-currenttime');

			var prevTime = 0;
			media.addEventListener('timeupdate',function() {
				if (t.controlsAreVisible && t.media) {
					var currentTime = !isNaN(t.media.currentTime) ? t.media.currentTime : 0;
					if (currentTime != prevTime) {
						player.updateCurrent();
						prevTime = currentTime;
					}
				}
			}, false);
		},


		buildduration: function(player, controls, layers, media) {
			var t = this, duration = t.media.duration ? t.media.duration : t.options.duration;

			if (controls.children().last().find('.mejs-currenttime').length > 0) {
				$(t.options.timeAndDurationSeparator +
					'<span class="mejs-duration">' +
						mejs.Utility.secondsToTimeCode(duration, duration > 3600) +
					'</span>')
					.appendTo(controls.find('.mejs-time'));
			} else {
				// add class to current time
				controls.find('.mejs-currenttime').parent().addClass('mejs-currenttime-container');

				$('<div class="mejs-time mejs-duration-container">'+
					'<span class="mejs-duration">' +
							mejs.Utility.secondsToTimeCode(duration, duration > 3600) +
					'</span>' +
				'</div>')
				.appendTo(controls);
			}

			t.durationD = t.controls.find('.mejs-duration');

			var prevDuration = 0;
			media.addEventListener('timeupdate',function() {
				if(t.controlsAreVisible) {
					if (prevDuration != duration) {player.updateDuration(); prevDuration = duration;}
				}
			}, false);
		},

		updateCurrent: function() {
			var t = this;
			if (!t.media) return;
			var currentTime = !isNaN(t.media.currentTime) ? t.media.currentTime : 0;

			if (t.currenttime && t.controlsAreVisible) {
				t.currenttime.html(mejs.Utility.secondsToTimeCode(currentTime, t.media.duration > 3600));
			}
		},

		updateDuration: function() {
			var t = this;

			if (!t.controlsAreVisible) return;
			var duration = t.options.duration > 0 ? t.options.duration : t.media.duration;
			if (isNaN(duration)) duration = 0;

			//Toggle the long video class if the video is longer than an hour.
			t.container.toggleClass("mejs-long-video", duration > 3600);

			if (t.durationD && duration) {
				t.durationD.html(mejs.Utility.secondsToTimeCode(duration, duration > 3600));
			}
		}
	});

})(mejs.$);

(function($) {
	$.extend(mejs.MepDefaults, {
		muteText: "Отключение звука (m)",
		hideVolumeOnMobile: true,

		audioVolume: 'horizontal',
		videoVolume: 'horizontal',

		initVolume: 1
	});

	$.extend(mejs.MediaElementPlayer.prototype, {
		buildvolume: function(player, controls, layers, media) {
			// Android and iOS don't support volume controls
			if (mejs.MediaFeatures.isMobile && this.options.hideVolumeOnMobile)
				return;

			var t = this,
				mode = (t.isVideo) ? t.options.videoVolume : t.options.audioVolume,
				mute = (mode == 'horizontal') ?

				// horizontal version
				$('<div class="mejs-button mejs-volume-button mejs-mute">'+
					'<button type="button" aria-controls="' + t.id + '" title="' + t.options.muteText + '" aria-label="' + t.options.muteText + '"></button>'+
				'</div>' +
				'<div class="mejs-horizontal-volume-slider">'+ // outer background
					'<div class="mejs-horizontal-volume-total"></div>'+ // line background
					'<div class="mejs-horizontal-volume-current"></div>'+ // current volume
					'<div class="mejs-horizontal-volume-handle"></div>'+ // handle
				'</div>'
				)
					.appendTo(controls) :

				// vertical version
				$('<div class="mejs-button mejs-volume-button mejs-mute">'+
					'<button type="button" aria-controls="' + t.id + '" title="' + t.options.muteText + '" aria-label="' + t.options.muteText + '"></button>'+
					'<div class="mejs-volume-slider">'+ // outer background
						'<div class="mejs-volume-total"></div>'+ // line background
						'<div class="mejs-volume-current"></div>'+ // current volume
						'<div class="mejs-volume-handle"></div>'+ // handle
					'</div>'+
				'</div>')
					.appendTo(controls),
			volumeSlider = t.container.find('.mejs-volume-slider, .mejs-horizontal-volume-slider'),
			volumeTotal = t.container.find('.mejs-volume-total, .mejs-horizontal-volume-total'),
			volumeCurrent = t.container.find('.mejs-volume-current, .mejs-horizontal-volume-current'),
			volumeHandle = t.container.find('.mejs-volume-handle, .mejs-horizontal-volume-handle'),

			positionVolumeHandle = function(volume, secondTry) {
				// correct to 0-1
				volume = Math.max(0,volume);
				volume = Math.min(volume,1);

				if (volumeSlider[0].style.display == 'none' && typeof secondTry === 'undefined') {
					volumeSlider.show();
					positionVolumeHandle(volume, true);
					volumeSlider.hide()
					return;
				}

				// ajust mute button style
				if (volume == 0) {
					mute.removeClass('mejs-mute').addClass('mejs-unmute');
				} else {
					mute.removeClass('mejs-unmute').addClass('mejs-mute');
				}

				let totalPosition = volumeTotal.position();
				// position slider
				if (mode == 'vertical') {
					var
						// height of the full size volume slider background
						totalHeight = volumeTotal.height(),
						// the new top position based on the current volume
						// 70% volume on 100px height == top:30px
						newTop = totalHeight - (totalHeight * volume);
					// handle
					volumeHandle.css('top', Math.round(totalPosition.top + newTop - (volumeHandle.height() / 2)));
					// show the current visibility
					volumeCurrent.height(Math.round(totalHeight - newTop));
					volumeCurrent.css('top', Math.round(totalPosition.top + newTop));
				} else {
					var
						// height of the full size volume slider background
						totalWidth = volumeTotal.width(),
						// the new left position based on the current volume
						newLeft = totalWidth * volume;
					// handle
					volumeHandle.css('left', Math.round(totalPosition.left + newLeft - (volumeHandle.width() / 2)));
					// rezize the current part of the volume bar
					volumeCurrent.width(Math.round(newLeft));
				}
			},
			handleVolumeMove = function(e) {
				var volume = null,
					totalOffset = volumeTotal.offset();

				// calculate the new volume based on the moust position
				if (mode == 'vertical') {

					var
						railHeight = volumeTotal.height(),
						newY = e.pageY - totalOffset.top;

					volume = (railHeight - newY) / railHeight;

					// the controls just hide themselves (usually when mouse moves too far up)
					if (totalOffset.top == 0 || totalOffset.left == 0)
						return;

				} else {
					var
						railWidth = volumeTotal.width(),
						newX = e.pageX - totalOffset.left;

					volume = newX / railWidth;
				}

				// ensure the volume isn't outside 0-1
				volume = Math.max(0,volume);
				volume = Math.min(volume,1);

				// position the slider and handle
				positionVolumeHandle(volume);

				// set the media object (this will trigger the volumechanged event)
				if (volume == 0) {
					media.setMuted(true);
				} else {
					media.setMuted(false);
				}
				media.setVolume(volume);
			},
			mouseIsDown = false,
			mouseIsOver = false;

			// SLIDER

			mute
				.on('mouseenter', function() {
					volumeSlider.show();
					mouseIsOver = true;
				}).on('mouseleave', function() {
					mouseIsOver = false;

					if (!mouseIsDown && mode == 'vertical') {
						volumeSlider.hide();
					}
				});

			volumeSlider
				.on('mouseover', function() {
					mouseIsOver = true;
				})
				.on('mousedown', function (e) {
					handleVolumeMove(e);
					t.globalBind('mousemove.vol', function(e) {
						handleVolumeMove(e);
					});
					t.globalBind('mouseup.vol', function () {
						mouseIsDown = false;
						t.globalUnbind('mouseup.vol');
						t.globalUnbind('mousemove.vol');

						if (!mouseIsOver && mode == 'vertical') {
							volumeSlider.hide();
						}
					});
					mouseIsDown = true;

					return false;
				});


			// MUTE button
			mute.find('button').on('click', function() {
				media.setMuted( !media.muted );
			});

			// listen for volume change events from other sources
			media.addEventListener('volumechange', function() {
				if (!mouseIsDown) {
					if (media.muted) {
						positionVolumeHandle(0);
						mute.removeClass('mejs-mute').addClass('mejs-unmute');
					} else {
						positionVolumeHandle(media.getVolume());
						mute.removeClass('mejs-unmute').addClass('mejs-mute');
					}
				}
			}, false);

			if (t.container) {
				// set initial volume
				var volume = t.options.initVolume;

				// mutes the media and sets the volume icon muted if the initial volume is set to 0
				if (volume === 0) media.setMuted(true);

				if (media.pluginType === 'native') {
					media.volume = volume;
				}
				positionVolumeHandle(volume);
			}
		}
	});

})(mejs.$);

(function($) {
	$.extend(mejs.MepDefaults, {
		newWindowCallback: function() { return '';},
		fullscreenText: "Полноэкранный режим (f)"
	});

	$.extend(mejs.MediaElementPlayer.prototype, {
		isFullScreen: false,

		isNativeFullScreen: false,

		isInIframe: false,

		buildfullscreen: function(player, controls) {
			if (!player.isVideo)
				return;

			player.isInIframe = (window.location != window.parent.location);

			// native events
			if (mejs.MediaFeatures.hasTrueNativeFullScreen) {
				// chrome doesn't alays fire this in an iframe
				var func = function() {
					if (player.isFullScreen) {
						if (mejs.MediaFeatures.isFullScreen()) {
							player.isNativeFullScreen = true;
							// reset the controls once we are fully in full screen
							player.setControlsSize();
						} else {
							player.isNativeFullScreen = false;
							// when a user presses ESC
							// make sure to put the player back into place
							player.exitFullScreen();
						}
					}
				};

				player.container.on(mejs.MediaFeatures.fullScreenEventName, func);
			}

			var t = this,
				fullscreenBtn =
					$('<div class="mejs-button mejs-fullscreen-button">' +
						'<button type="button" aria-controls="' + t.id + '" title="' + t.options.fullscreenText + '" aria-label="' + t.options.fullscreenText + '"></button>' +
					'</div>')
					.appendTo(controls);

			if (t.media.pluginType === 'native' || t.media.pluginType === 'youtube') {
				fullscreenBtn.on('click', function() {
					var isFullScreen = (mejs.MediaFeatures.hasTrueNativeFullScreen && mejs.MediaFeatures.isFullScreen()) || player.isFullScreen;

					if (isFullScreen) {
						player.exitFullScreen();
					} else {
						// On iPad, fullscreen is not possible before loading
						if (t.media.readyState == 0) {
							t.load();
						}

						player.enterFullScreen();
					}
				});
			}

			player.fullscreenBtn = fullscreenBtn;

			t.globalBind('keydown',function (e) {
				if (((mejs.MediaFeatures.hasTrueNativeFullScreen && mejs.MediaFeatures.isFullScreen()) || t.isFullScreen) && e.keyCode == 27) {
					player.exitFullScreen();
				}
			});
			t.normalHeight = 0;
			t.normalWidth = 0;
		},

		cleanfullscreen: function(player) {
			player.exitFullScreen();
		},

		containerSizeTimeout: null,

		enterFullScreen: function() {
			var t = this;

			// set it to not show scroll bars so 100% will work
			$(document.documentElement).addClass('mejs-fullscreen');

			// store sizing
			t.normalHeight = t.container.height();
			t.normalWidth = t.container.width();

			// attempt to do true fullscreen
			if (mejs.MediaFeatures.hasTrueNativeFullScreen) {
				mejs.MediaFeatures.requestFullScreen(t.container[0]);
			}

			// check for iframe launch
			if (t.isInIframe) {
				var url = t.options.newWindowCallback(this);

				if (url !== '') {
					// launch immediately
					t.pause();
					window.open(url, t.id, 'top=0,left=0,width=' + screen.availWidth + ',height=' + screen.availHeight + ',resizable=yes,scrollbars=no,status=no,toolbar=no');
					return;
				}
			}

			// full window code

			// make full size
			t.container
				.addClass('mejs-container-fullscreen');

			if (t.fullscreenBtn) {
				t.fullscreenBtn
					.removeClass('mejs-fullscreen')
					.addClass('mejs-unfullscreen');
			}

			t.setControlsSize();
			t.isFullScreen = true;

			t.container.trigger('mejsEvent', ['fullscreen']);
		},

		exitFullScreen: function() {
			var t = this;

			if(typeof t.normalWidth == 'undefined') return;
			// Prevent container from attempting to stretch a second time
			clearTimeout(t.containerSizeTimeout);
			// come outo of native fullscreen
			if (mejs.MediaFeatures.hasTrueNativeFullScreen && (mejs.MediaFeatures.isFullScreen() || t.isFullScreen)) {
				mejs.MediaFeatures.cancelFullScreen();
			}

			// restore scroll bars to document
			$(document.documentElement).removeClass('mejs-fullscreen');

			t.container
				.removeClass('mejs-container-fullscreen');

			t.fullscreenBtn
				.removeClass('mejs-unfullscreen')
				.addClass('mejs-fullscreen');

			t.setControlsSize();
			t.isFullScreen = false;

			t.container.trigger('mejsEvent', ['exitFullscreen']);
		}
	});
})(mejs.$);

(function($) {

	// add extra default options
	$.extend(mejs.MepDefaults, {
		tracksText: "Субтитры (s)",

		// option to remove the [cc] button when no <track kind="subtitles"> are present
		hideCaptionsButtonWhenEmpty: true,

		// If true and we only have one track, change captions to popup
		toggleCaptionsButtonWhenOnlyOne: false,

		// #id or .class
		slidesSelector: ''
	});

	$.extend(mejs.MediaElementPlayer.prototype, {
		hasChapters: false,

		cleartracks: function(player) {
			if (player) {
				if (player.captions) player.captions.remove();
				if (player.captionsText) player.captionsText.remove();
				if (player.captionsButton) player.captionsButton.remove();
			}
		},

		buildtracks: function(player, controls, layers, media) {
			var t = this, i;

			t.cleartracks(player, controls, layers, media);

			if (player.tracks.length == 0)
				return;

			player.captions =
					$('<div class="mejs-captions-layer mejs-layer"><div class="mejs-captions-position mejs-captions-position-hover"><span class="mejs-captions-text"></span></div></div>')
						.prependTo(layers).hide();
			player.captionsText = player.captions.find('.mejs-captions-text');
			player.captionsButton =
					$('<div class="mejs-button mejs-captions-button">'+
						'<button type="button" aria-controls="' + t.id + '" title="' + t.options.tracksText + '" aria-label="' + t.options.tracksText + '"></button>'+
						'<div class="mejs-captions-selector mejs-selector">'+
							'<ul>'+
								'<li>'+
									'<input type="radio" name="' + player.id + '_captions" id="' + player.id + '_captions_none" value="none" checked="checked" />' +
									'<label for="' + player.id + '_captions_none">Нет</label>'+
								'</li>'	+
							'</ul>'+
						'</div>'+
					'</div>')
						.appendTo(controls);

			var subtitleCount = 0;
			for (i = 0; i < player.tracks.length; i++) {
				if (player.tracks[i].kind == 'subtitles') {
					subtitleCount++;
				}
				player.tracks[i].index = i;
			}

			// if only one language then just make the button a toggle
			if (t.options.toggleCaptionsButtonWhenOnlyOne && subtitleCount == 1){
				// click
				player.captionsButton.on('click',function() {
					var idx = player.selectedTrack == null ? player.tracks[0].index : 'none';
					player.setTrack(idx);
				});
			} else {
				player.captionsButton
				// handle clicks to the language radio buttons
				.on('click','input[type=radio]',function() {
					player.setTrack(this.value);
				});
			}

			if (!player.options.alwaysShowControls) {
				// move with controls
				player.container
					.on('controlsshown', function () {
						// push captions above controls
						player.container.find('.mejs-captions-position').addClass('mejs-captions-position-hover');

					})
					.on('controlshidden', function () {
						if (!media.paused) {
							// move back to normal place
							player.container.find('.mejs-captions-position').removeClass('mejs-captions-position-hover');
						}
					});
			} else {
				player.container.find('.mejs-captions-position').addClass('mejs-captions-position-hover');
			}

			player.trackToLoad = -1;
			player.selectedTrack = null;
			player.isLoadingTrack = false;

			if (sessionStorage.sub) {
				var parts = sessionStorage.sub.split(':');
				if (parts[0] == t.options.parent.channel && parts[1] != 'none') {
					for (i = 0; i < player.tracks.length; i++) {
						if (parts[1] == player.tracks[i].index) player.tracks[i].default = true;
					}
				}
			}

			// add to list
			for (i = 0; i < player.tracks.length; i++) {
				if (player.tracks[i].kind == 'subtitles') {
					player.addTrackButton(player.tracks[i]);
					player.enableTrackButton(player.tracks[i]);
				}
			}

			media.addEventListener('timeupdate',function() {
				player.displayCaptions();
			}, false);
		},

		setTrack: function(idx) {
			var t = this;
			if(!t.captions) return;

			sessionStorage.setItem('sub', t.options.parent.channel + ':' + idx);

			if(!t.captions.hasClass('mejs-captions-layer')) {
				//t.selectedTrack.entries.ass.destroy();
				t.selectedTrack.entries.ass.dispose();
				//t.selectedTrack.entries.ass = true;
				t.captions.addClass('mejs-captions-layer');
				//t.container.unbind(mejs.MediaFeatures.fullScreenEventName);
			}

			t.captions.hide();
			if (idx == 'none') {
				t.selectedTrack = null;
				t.captionsButton.removeClass('mejs-captions-enabled');
			} else {
				if (t.selectedTrack == null)
					t.captionsButton.addClass('mejs-captions-enabled');
				t.selectedTrack = t.tracks[idx];

				if(!t.selectedTrack.isLoaded) {
					t.loadTrack(idx, function() {
						t.setLoadedTrack();
					});
				} else t.setLoadedTrack();
			}
		},

		setLoadedTrack: function() {
			var t = this, track = t.selectedTrack;
			if(track == null) return;

			t.captions.attr('lang', track.srclang);
			if (typeof track.entries.ass == 'object') {
				t.captions.removeClass('mejs-captions-layer').show();
				//track.entries.ass.disable();
				track.entries.ass.dispose();
			}
			if (track.entries.ass === true) {
				let octopusInit = function() {
					t.captions.removeClass('mejs-captions-layer').show();
					var options = {
						video: t.media,
						subUrl: track.src,
						workerUrl: '/octopus/subtitles-octopus-worker.js',
						timeOffset: t.media.dash_player ? -0.05 : 0
					};
					track.entries.ass = new octopus.SubtitlesOctopus(options);
				}
				octopusInit();
			}
			t.displayCaptions();
		},

		loadTrack: function(index, callback) {
			var
				t = this,
				track = t.tracks[index],
				after = function() {
					track.isLoaded = true;
					t.isLoadingTrack = false;
					track.lastID = 0;
					callback();
				};

			t.isLoadingTrack = true;

			if (track.src.indexOf('.ass') != -1 || track.src.indexOf('.ssa') != -1) {
				track.entries = {'ass': true, 'times': []};
				after();
				return;
			}
			window.ajax(track.src, [], function(d, error) {
				if (error) {
					$('#mep_0_captions_none').prop('checked', true).trigger('click');
				} else {
					if (track.src.indexOf('.ass') != -1 || track.src.indexOf('.ssa') != -1) {
						track.entries = { 'ass': true, 'times': [], 'data': d };
					} else {
						// parse the loaded file
						track.entries = mejs.TrackFormatParser.webvvt.parse(d);
					}

					after();
				}
			});
		},

		enableTrackButton: function(track) {
			var t = this;

			t.captionsButton
				.find('input[value="' + track.index + '"]')
					.prop('disabled',false)
				.siblings('label')
					.html( track.label );

			if(track.default) $('#' + t.id + '_captions_' + track.index).prop('checked', true).trigger('click');
		},

		addTrackButton: function(track) {
			var t = this;
			if (track.label === '') {
				track.label = mejs.language.codes[track.srclang] || track.srclang;
			}

			t.captionsButton.find('ul').append(
				$('<li>'+
					'<input type="radio" name="' + t.id + '_captions" id="' + t.id + '_captions_' + track.index + '" value="' + track.index + '" disabled="disabled" />' +
					'<label for="' + t.id + '_captions_' + track.index + '">' + track.label + ' (loading)' + '</label>'+
				'</li>')
			);

			// remove this from the dropdownlist (if it exists)
			t.container.find('.mejs-captions-translations option[value="' + track.index + '"]').remove();
		},

		displayCaptions: function() {
			if (typeof this.tracks == 'undefined')
				return;

			var t = this, i, track = t.selectedTrack;

			if (!track || typeof track.entries.ass != 'undefined' || !t.media) return

			if (track !== null && track.isLoaded) {
				if (track.lastTime && track.lastTime > t.media.currentTime) track.lastID = 0; // перемотка назад
				for (i = track.lastID; i < track.entries.times.length; i++) {
					if (t.media.currentTime >= track.entries.times[i].start && t.media.currentTime <= track.entries.times[i].stop) {
						if (i && i == track.lastID) return;
						t.captionsText.html(track.entries.text[i]).attr('class', 'mejs-captions-text ' + (track.entries.times[i].identifier || ''));
						t.captions.show().height(0);
						track.lastID = i;
						track.lastTime = t.media.currentTime;
						return; // exit out if one is visible;
					}
				}
			}
			t.captions.hide();
		},
	});

	mejs.language = {
		codes:  {
			ru:'Russian',
			en:'English'
		}
	};

	/*
	Parses WebVVT format which should be formatted as
	================================
	WEBVTT

	1
	00:00:01,1 --> 00:00:05,000
	A line of text

	2
	00:01:15,1 --> 00:02:05,000
	A second line of text

	===============================

	Adapted from: http://www.delphiki.com/html5/playr
	*/
	mejs.TrackFormatParser = {
		webvvt: {
			// match start "chapter-" (or anythingelse)
			pattern_identifier: /^([a-zA-z]+-)?[0-9]+$/,
			pattern_timecode: /^([0-9]{2}:[0-9]{2}:[0-9]{2}([,.][0-9]{1,3})?) --> ([0-9]{2}:[0-9]{2}:[0-9]{2}([,.][0-9]{3})?)(.*)$/,

			parse: function(trackText) {
				if(!trackText) return;
				var
					i = 0,
					lines = mejs.TrackFormatParser.split2(trackText, /\r?\n/),
					entries = {text:[], times:[]},
					timecode,
					text;
				for(; i<lines.length; i++) {
					// check for the line number
					if (this.pattern_identifier.exec(lines[i])){
						// skip to the next line where the start --> end time code should be
						i++;
						timecode = this.pattern_timecode.exec(lines[i]);

						if (timecode && i<lines.length){
							i++;
							// grab all the (possibly multi-line) text that follows
							text = lines[i];
							i++;
							while(lines[i] !== '' && i<lines.length){
								text = text + '\n' + lines[i];
								i++;
							}
							text = text.trim().replace(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig, "<a href='$1' target='_blank'>$1</a>");
							// Text is in a different array so I can use .join
							text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
							entries.text.push(text);
							entries.times.push(
							{
								start: (mejs.Utility.convertSMPTEtoSeconds(timecode[1]) == 0) ? 0.200 : mejs.Utility.convertSMPTEtoSeconds(timecode[1]),
								stop: mejs.Utility.convertSMPTEtoSeconds(timecode[3]),
								settings: timecode[5]
							});
						}
					}
				}
				return entries;
			}
		},
		split2: function (text, regex) {
			return text.split(regex);
		}
	};
})(mejs.$);

// Sound Tracks Plugin
(function($) {
	$.extend(mejs.MepDefaults, {});

	$.extend(mejs.MediaElementPlayer.prototype, {
		audioTrack: 1,
		Tracks : {},
		buildsoundtracks: function(player, controls, layers, media) {
			var t = this,
				Text = 'Звуковая дорожка';
			if (t.options.parent.video.audio < 2) return;

			t.Tracks = t.options.parent.video.extra.audio_info ? t.options.parent.video.extra.audio_info : [];

			player.soundtracksButton =
				$('<div class="mejs-button mejs-soundtracks-button">'+
					'<button type="button" aria-controls="' + t.id + '" title="' + Text + '" aria-label="' + Text + '"></button>'+
					'<div class="mejs-soundtracks-selector mejs-selector"><ul></ul>'+
					'</div></div>').appendTo(controls)
					.on('click', 'input[type=radio]', function() {
						player.changeTrack(media, this.value);
					});
			for (var i = 1; i <= t.options.parent.video.audio; ++i) {
				player.addSourceButton(i, t.audioTrack == i);
			}
		},
		addSourceButton: function(i, isCurrent) {
			var t = this, title = t.Tracks[i - 1] ? t.Tracks[i - 1] : 'Звуковая дорожка №' + i;
			t.soundtracksButton.find('ul').append(
				$('<li>'+
					'<input name="soundtrack" type="radio" id="' + t.id + '_soundtrack_' + i + '" value="' + i + '" ' + (isCurrent ? 'checked="checked"' : '') + ' />'+
					'<label for="' + t.id + '_soundtrack_' + i + '">' + title + '</label>'+
				'</li>')
			);
		},
		changeTrack: function(media, idx) {
			let t = this;
			if (t.audioTrack != idx && !t.media.dash_player) {
				t.audioTrack = idx;
				let currentTime = media.currentTime, paused = media.paused, src;
				if (t.src.indexOf('stream=')) src = t.src.replace(/(stream=\d+)/, "stream=" + idx);
				else src = t.src + (idx > 1 ? "?stream=" + idx : "");
				media.setSrc(src);
				media.load();
				if (!paused) media.play();
				media.addEventListener('loadedmetadata', function() {
					if (currentTime) media.setCurrentTime(currentTime);
					currentTime = 0;
				}, true);
			}
			if (t.audioTrack != idx && t.media.dash_player) {
				t.audioTrack = idx;
				t.media.dash_player.selectAudioLanguage('au' + idx);
				// при выборе дорожки до воспризведения selectAudioLanguage не срабатывает. Возможно, исправится при обновлении shaka player
				t.media.dash_player.configure({
					preferredAudioLanguage: 'au' + idx,
				});
			}
		},
		nextTrack: function(media) {
			var t = this;
			if (t.audio < 2) return;

			var idx = parseInt(t.Track) + 1;
			if (idx > t.audio) idx = 1;

			t.changeTrack(media, idx);
		}
	});
})(mejs.$);

(function($) {
	$.extend(mejs.MepDefaults, {});

	$.extend(mejs.MediaElementPlayer.prototype, {
		videoTrack: 0,
		videoTracks: {},
		videoTrackAuto: false,
		buildvideotracks: function(player, controls) {
			let t = this, Text = 'Качество';
			if (t.options.parent.video.size_count < 2) return;

			if (t.media.dash_player) {
				t.media.dash_player.addEventListener('adaptation', t.onAdaptation.bind(t));
			}

			t.videoTracks = t.options.parent.video.size ? t.options.parent.video.size : [];

			player.videoTracksButton =
				$('<div class="mejs-button mejs-videotracks-button">'+
					'<button type="button" aria-controls="' + t.id + '" title="' + Text + '" aria-label="' + Text + '"></button>'+
					'<div class="mejs-videotracks-selector mejs-selector"><ul></ul>'+
					'</div></div>').appendTo(controls)
					.on('click', 'input[type=radio]', function() {
						player.changeVideoTrack(parseInt(this.value));
					}).on('click', 'input[type=checkbox]', function() {
						if (!this.checked) t.videoDisableAuto();
						else {
							t.videoTrackAuto = 1;
							t.media.dash_player.configure({ abr: { enabled: true } });
						}
					});

			t.videoTracks.forEach((track) => {
				if (track.is_primary) t.videoTrack = track.i;
			});

			t.videoTracksButton.find('ul').append(
				$('<li>'+
					'<input type="checkbox" name="videoTrackAuto" id="' + t.id + '_videotrackauto" ' + (t.videoTrackAuto ? 'checked' : '') + '/>'+
					'<label for="' + t.id + '_videotrackauto">Авто</label>'+
				'</li><li><hr></li>')
			);

			t.videoTracks.forEach((track) => {
				player.addQualityButton(track);
			});
		},
		addQualityButton: function(track) {
			let t = this, title = track.size + 'p', i = track.i;
			t.videoTracksButton.find('ul').append(
				$('<li>'+
					'<input type="radio" name="videoTrack" id="' + t.id + '_videotrack_' + i + '" value="' + i + '" ' + (i == t.videoTrack ? 'checked="checked"' : '') + ' />'+
					'<label for="' + t.id + '_videotrack_' + i + '">' + title + '</label>'+
				'</li>')
			);
		},
		onAdaptation: function() {
			let t = this,
				variants = t.media.dash_player.getVariantTracks(),
				active = variants.find((track) => {return track.active;}),
				videoTracks = t.getVideoTracks(variants),
				idx = 0;
			if (t.options.debug) console.log(t.media.dash_player.getStats(), navigator.connection);

			videoTracks.forEach((track, index) => {
				if (track == active.videoId) {
					idx = index;
				}
			});

			t.videoTrack = idx;
			document.querySelector('#' + t.id + '_videotrack_' + idx).checked = true;
		},
		videoDisableAuto: function() {
			if (!this.videoTrackAuto) return;
			this.videoTrackAuto = 0;
			this.media.dash_player.configure({abr: {enabled: false}});
			document.querySelector('#' + this.id + '_videotrackauto').checked = false;
		},
		getVideoTracks: function(variants) {
			let videoTracks = variants.map(function(variant) {
				return variant.videoId;
			}).filter(function(item, position, self) {
				return self.indexOf(item) == position;
			});
			return videoTracks;
		},
		changeVideoTrack: function(idx) {
			let t = this;

			if (t.videoTrack != idx) {
				t.videoDisableAuto();
				t.load().then(function() {
					t.videoTrack = idx;
					var variants = t.media.dash_player.getVariantTracks(), videoTracks = t.getVideoTracks(variants);

					var videoId = videoTracks[idx];
					if (!videoId) return;

					var streams = variants.filter(function(variant) {
						return variant.videoId == videoId;
					});

					if (streams.length) {
						t.media.dash_player.selectVariantTrack(streams[t.audioTrack - 1]);
						t.onAdaptation();
					}
				});
			}
		},
	});
})(mejs.$);

(function($) {

	// Speed
	$.extend(mejs.MepDefaults, {
		speeds: ['2', '1.75', '1.5', '1.25', '1', '0.75'],

		defaultSpeed: '1',

		speedChar: 'x'
	});

	$.extend(mejs.MediaElementPlayer.prototype, {
		buildspeed: function(player, controls, layers, media) {
			var t = this;

			if(t.media.pluginType == 'native') {
				var storedSpeed = localStorage.getItem('player.speed');
				if (storedSpeed) t.options.defaultSpeed = storedSpeed;
				var
					speedButton = null,
					speedSelector = null,
					playbackSpeed = null,
					html = '<div class="mejs-button mejs-speed-button">' +
								'<button type="button">' + t.options.defaultSpeed + t.options.speedChar + '</button>' +
								'<div class="mejs-speed-selector mejs-selector">' +
								'<ul>';

				t.options.speeds.sort(function(a, b) {
					return parseFloat(b) - parseFloat(a);
				});

				for (var i = 0, il = t.options.speeds.length; i<il; i++) {
					html += '<li>' +
								'<input type="radio" name="speed" ' +
											'value="' + t.options.speeds[i] + '" ' +
											'id="' + t.options.speeds[i] + '" ' +
											(t.options.speeds[i] == t.options.defaultSpeed ? ' checked' : '') +
											' />' +
								'<label for="' + t.options.speeds[i] + '" ' +
											(t.options.speeds[i] == t.options.defaultSpeed ? ' class="mejs-speed-selected"' : '') +
											'>' + t.options.speeds[i] + t.options.speedChar + '</label>' +
							'</li>';
				}
				html += '</ul></div></div>';

				speedButton = $(html).appendTo(controls);
				speedSelector = speedButton.find('.mejs-speed-selector');

				playbackSpeed = t.options.defaultSpeed;

				media.addEventListener('loadedmetadata', function() {
					if(playbackSpeed) media.playbackRate = parseFloat(playbackSpeed);
				}, true);

				speedSelector
					.on('click', 'input[type="radio"]', function() {
						const newSpeed = $(this).attr('value');
						t.setSpeed(newSpeed);
					});
			}
		},
		setSpeed(newSpeed) {
			var t = this;
			localStorage.setItem('player.speed', newSpeed);
			t.options.defaultSpeed = newSpeed;
			t.media.playbackRate = parseFloat(newSpeed);
			t.speedButton.find('button').html(newSpeed + t.options.speedChar);
			t.speedButton.find('.mejs-speed-selected').removeClass('mejs-speed-selected');
			t.speedButton.find('input[type="radio"]:checked').next().addClass('mejs-speed-selected');
			t.setMessage('x' + newSpeed);
		},
		stepSpeed(direction) {
			var t = this, newSpeed = parseFloat(t.options.defaultSpeed) + (direction > 0 ? 0.25 : -0.25);
			if (newSpeed < 0.75) newSpeed = 0.75;
			else if (newSpeed > 2) newSpeed = 2;
			t.setSpeed(newSpeed.toString());
		}
	});

})(mejs.$);

/*
* ContextMenu Plugin
*/

(function($) {
	$.extend(mejs.MediaElementPlayer.prototype, {
		buildcontextmenu: function(player) {
			if (player.options.debug) return;

			// create events for showing context menu
			player.container.on('contextmenu', function(e) {
				e.preventDefault();
				player.parent.showMenu = true;
				player.parent.menuPos = [e.clientX-1, e.clientY-1];
				return false;
			});

			// render context menu on long touch
			var timer;
			player.container.on('touchstart', function(e) {
				timer = e.timeStamp;
			}).on('touchend', function(e) {
				if (e.timeStamp - timer > 800) {
					player.parent.showMenu = true;
					player.parent.menuPos = [e.clientX-1, e.clientY-1];
				}
			});
		},
	});
})(mejs.$);
